import {Content} from './content';
import {TitleNode} from '../../view/node/titleNode';
import * as $ from 'jquery';


export class Image extends Content {


  content(): string {
    return '<img src="' + (this.model.resource.preview.thumbnailMedium) + '">';
  }

  save(): boolean {
    console.log('saving');
    return true;
  }


  contentReadOnly(): string {
    console.log('image model', this.model);
    return '<img class="image large" src="data:image/jpeg;base64,' + (this.model.resource.preview.thumbnailMedium) + '">';
  }
}
