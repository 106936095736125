

import {Vector, Vector3} from "./vectors";
import {BMath} from "./bMath";

export class Bezier
{

    protected points: Array<Vector>;
    protected cachedPoints: Array<Vector>;

    constructor(points: Array<Vector>) {
        this.points = points;
    }

    public get(t: number): Vector
    {

        let res = new Vector(0, 0);
        let n = this.points.length - 1;

        for (let i=0; i<=n; i++)
        {

            let b = BMath.binomArray(n);
            let partialSum = b[i] * Math.pow(t, i) * Math.pow(1 - t, n - i);
            res = res.add(this.points[i].mul(new Vector(partialSum, partialSum)));
        }

        return res;

    }

    public getValue(t: number, interpolate?: boolean): number
    {
        if (interpolate)
        {

            for (let i=0; i < this.cachedPoints.length; i++)
            {
                if (t == this.cachedPoints[i].x)
                    return this.cachedPoints[i].y;
                if (t < this.cachedPoints[i].x)
                {
                    let diffX = this.cachedPoints[i].x - this.cachedPoints[i - 1].x;
                    let diffY = this.cachedPoints[i].y - this.cachedPoints[i - 1].y;
                    let diff = diffY / diffX;
                    let T = t - this.cachedPoints[i - 1].x;
                    return T * diff + this.cachedPoints[i - 1].y;
                }
            }

            return this.cachedPoints[this.cachedPoints.length - 1].y;
        }

        return this.get(t).y;
    }

    /**
     * The Cached points will also have a w-Value that represents the points speed
     * */
    public calcPoints(steps: number): void
    {
        this.cachedPoints = new Array<Vector>(steps + 1);
        let step = 1 / steps;
        let prev = this.points[0];
        let i = 0;
        let j = 0;
        for (let j=0; j<=steps; j++)
        {
            let vec = this.get(i);
            vec = new Vector(vec.x, vec.y, 0, vec.sub(prev).length());
            this.cachedPoints[j] = vec;
            prev = new Vector(vec.x, vec.y);
            i+=step;
        }
    }

    public getCachedPointsArray(): Array<Vector>
    {
        return this.cachedPoints;
    }
}




export class EaseOutBezier extends Bezier
{

    private static i: Bezier;
    public static instance(): Bezier
    {
        if (EaseOutBezier.i)
            return EaseOutBezier.i;
        else
            EaseOutBezier.i = new Bezier([
                new Vector(0.00, 0.0),
                new Vector(0.00, 0.0),
                new Vector(0.58, 1.0),
                new Vector(1.00, 1.0),
            ]);
        EaseOutBezier.i.calcPoints(30);
        return EaseOutBezier.i;
    }


}
