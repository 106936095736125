import {ObjectLayer} from './objectLayer';
import {Canvas} from './canvas';
import {Animator} from './tools/animator';
import {EaseOutBezier} from './tools/bezier';
import {Event} from './tools/event';
import {Action} from './action/action';
import {AddToUiAction} from './action/addToUiAction';
import {Actions} from "./action/actions";


export class UiLayer extends ObjectLayer {


  protected animator: Animator;

  constructor(canvas: Canvas, poolSize: number) {
    super(canvas, poolSize);
    this.hitOrMiss = false;
    this.animator = new Animator(300, EaseOutBezier.instance());
    this.animator.start();
    this.animator.update(1000);
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.animator.update(delta);
    this.alpha = this.animator.getValue();
  }

  preferredHoverCursor(): string {
    return 'pointer';
  }

  public fadeIn() {
    this.animator.start();
    this.animator.isFinished = false;
  }
  public fadeOut() {
    this.animator.reverse();
    this.animator.isFinished = false;
  }


  blockCanvasMove(): boolean {
    console.log('block canvas move?', super.blockCanvasMove());
    return super.blockCanvasMove();
  }
}
