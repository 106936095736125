import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Blockable} from '../eleanor/interfaces/blockable';
import {Block, Scales} from './block';
import {CompactRenderer} from './compactRenderer';
import {ButtonGroup, Positioning} from '../ui/buttonGroup';
import {Button} from '../ui/button';
import {Action} from '../eleanor/action/action';
import {EmptyAction} from '../eleanor/action/emptyAction';
import {Event} from '../eleanor/tools/event';
import {ModalAction} from '../eleanor/action/modalAction';
import {Actions} from "../eleanor/action/actions";
import {MoveEvent} from "../eleanor/tools/moveEvent";
import {Todo} from './todo';

export abstract class bNode extends ComplexRenderObject implements Blockable
{



  padding: Vector;
  isLoaded: boolean;
  protected block: Block;
  public holes: Array<HoleDirection>;
  public prevHoles: Array<HoleDirection>;
  private _compact: CompactRenderer;
  public todo: Todo;

  constructor() {
    super();
    this.position = new Vector(0);
    this.fbo = new FrameBuffer(this);
    this.isDirty = true;
    this.padding = new Vector(0, 0);

    this.isLoaded = true;
    this.holes = [];
    this.prevHoles = [];

    this._compact = new CompactRenderer('black', 'white', new Vector(), 'b', 100);

  }

  public update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    if (this.todo) {
      this.todo.update(delta, zoom);
    }
  }

  render(batch: Batch): void {
    let small = this.dimensions.padding(new Vector(-this.padding.x, -this.padding.y));
    if (this.isDirty)
    {
      //Log.log("draw fbo");
      let myBatch: Batch = this.batch;
      //this.fbo.ctx.clearRect(0, 0, this.dimensions.width + this.padding.x, this.dimensions.height);
      myBatch.clearAll();
      this.renderContent(myBatch);


      if (this.todo) {
        this.todo.render(myBatch);
      }

      this.isDirty = false;//true || !this.isLoaded;
    }

    batch.drawImage(this.fbo.handle, this.position.x, this.position.y);

    }

  calculatedHeight(): number {
    return 24;
  }

  public setDirty()
  {
    this.isDirty = true;
    this.block.isDirty = true;
  }

  protected updateDimensions()
  {
    this.dimensions = this.block.contentDimensions.padding(this.padding);

    this.fbo.setDimensions(this.dimensions);
  }

  protected abstract renderContent(batch: Batch);

  public initialize(block: Block)
  {

    this.block = block;
    this.updateDimensions();
  }

  setY(value: number) {
    this.position = new Vector(this.position.x, value);
  }

  applyBlockWidth(): boolean {
    return false;
  }

  public isFirst(): boolean {
    return this.block.getFirst() == this;
  }
  public isLast(): boolean {
    return this.block.getLast() == this;
  }

  public worldPosition(batch): Vector {
    return this.block.position.add(this.position.mul(new Vector(this.block.getScale(batch), this.block.getScale(batch))).div(new Vector(batch.zoom, batch.zoom)));
  }

  public holeVectorFor(node: bNode, zoom: number): Vector {
    const scale: Scales = this.block.getScaleType(zoom);

    switch (scale) {
      case Scales.Tiny:
        return this.holeVectorForTiny(node, zoom);
      case Scales.Compact:
        return this.holeVectorForCompact(node, zoom);
      default:
        return this.holeVectorForNormal(node, zoom);
    }

  }

  protected holeVectorForNormal(node: bNode, zoom: number): Vector {
    const batch = this.block.batch;
    batch.zoom = zoom;
    const position = this.worldPosition(batch);
    const bPosition = node.worldPosition(batch);
    const diff = position.sub(bPosition);
    const dimensions =  new Dimensions(this.block.contentDimensionsWithPadding.width * this.block.getScale(batch) / zoom, this.block.contentDimensionsWithPadding.height * this.block.getScale(batch) / zoom);
    const bDimensions = new Dimensions(node.block.contentDimensionsWithPadding.width * Math.min(node.block.scale * zoom, 1), node.dimensions.height * Math.min(node.block.scale * zoom, 1));
    const buffer = 100;
    const off = 1;// Math.min(1, position.sub(bPosition).length() / 500) * buffer * zoom;//this.block.getScale(batch);

    if (this.isLast() && diff.y - buffer > 0 &&
      ( position.x - dimensions.width2 - buffer < bPosition.x + bDimensions.width2 ||
        position.x + dimensions.width2 + buffer > bPosition.x - bDimensions.width2)) {
      this.holes.push(HoleDirection.Down);
      if (!this.prevHoles.includes(HoleDirection.Down)) {
        this.block.isDirty = true;
      }
      return this.block.position.sub(new Vector(0, this.block.contentDimensionsWithPadding.height2 * (zoom <= 1 ? this.block.getScale(batch) / zoom : 1), 0, off));
    }
    else if (this.isFirst() && diff.y + buffer < 0 &&
      ( position.x - dimensions.width2 - buffer < bPosition.x + bDimensions.width2 ||
        position.x + dimensions.width2 + buffer > bPosition.x - bDimensions.width2)) {
      this.holes.push(HoleDirection.Up);
      if (!this.prevHoles.includes(HoleDirection.Up)) {
        this.block.isDirty = true;
      }
      return this.block.position.add(new Vector(0, this.block.contentDimensionsWithPadding.height2 * (zoom <= 1 ? this.block.getScale(batch) / zoom : 1), 0, off));
    }
    else {
      const vec = new Vector(dimensions.width2, -(this.dimensions.height2 - 20) * this.block.getScale(batch) / zoom, off, 0);
      if (diff.x >= 0) {
        const res = position.sub(vec);
        this.holes.push(HoleDirection.Left);
        if (!this.prevHoles.includes(HoleDirection.Left)) {
          this.block.isDirty = true;
        }
        //Log.log(res);
        return res;
      }
      else {
        const res = position.add(vec.mul(new Vector(1, -1, 1, 1)));
        this.holes.push(HoleDirection.Right);
        if (!this.prevHoles.includes(HoleDirection.Right)) {
          this.block.isDirty = true;
        }
        //Log.log(res);
        return res;
      }

    }
  }
  protected holeVectorForCompact(node: bNode, zoom: number): Vector {

    if (true || this.block.compactBlock.compacts.length === 1) {
      const vec = node.block.position.sub(this.block.position);
      const angle = Math.atan2(vec.y, vec.x);
      const x = Math.cos(angle);
      const y = Math.sin(angle);
      return this.block.position.add(new Vector(x * this._compact.radius, y * this._compact.radius, x, y));
    }
    const x = Math.cos(this._compact.usedAngle);
    const y = Math.sin(this._compact.usedAngle);
    return this.block.position.add(new Vector(x * this._compact.radius, y * this._compact.radius, x, y));
  }
  protected holeVectorForTiny(node: bNode, zoom: number): Vector {
    return this.block.position;
  }

  public clearHoles() {
    this.prevHoles = this.holes;
    this.holes = [];
  }

  getHoles(): Array<HoleDirection> {
    return this.holes;
  }


  public get compact(): CompactRenderer {
    return this._compact;
  }

  public set compact(value: CompactRenderer) {
    this._compact = value;
  }


  createButtons(): Array<ButtonGroup> {
    if (this.buttonGroup.length === 0) {
      this.buttonGroup = [
        new ButtonGroup (
          this.worldPosition(this.block.batch).add(new Vector(this.width2 + 30)),
          [
            new Button('\u2699', new ModalAction(this, this.typeKey()), new Dimensions(48, 48)),
          ],
          0,
          Positioning.Left,
          Positioning.Left),
      ];
      return this.buttonGroup;
    }
    console.log('button group exists');
    return [];
  }

  public click(event: Event): Event {
    const result = super.click(event);
    result.actions.setAllChild(this);
    return result;
  }

  public doubleClick(event: Event): Event {
    let result = super.doubleClick(event);
    result.actions.addAction(new ModalAction(this, this.typeKey()));
    result.actions.setAllChild(this);
    return result;
  }

  public onBlockMove(event: MoveEvent): MoveEvent {
    this.updateButtonPositions(event.delta);
    return event;
  }

  public updateButtonPositions(delta: Vector) {
    for (const buttonGroup of this.buttonGroup) {
      buttonGroup.position = buttonGroup.position.add(delta);
    }
  }


  blockWidth(): number {
    return this.dimensions.width;
  }

  public abstract typeKey(): string;
}


export enum HoleDirection {
  Up, Down, Left, Right
}
