import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Circle} from '../eleanor/shapes/circle';
import {Vector} from '../eleanor/tools/vectors';
import {Animator} from '../eleanor/tools/animator';
import {Bezier, EaseOutBezier} from '../eleanor/tools/bezier';
import {RoundClip} from './roundClip';
import {Rect} from '../eleanor/shapes/rect';


export class LoadingBlock extends ComplexRenderObject {


  private static instance;

  public static getInstance(): LoadingBlock {
    if (!this.instance)
      this.instance = new LoadingBlock();
    return this.instance;
  }

  public static draw(batch: Batch, position: Vector)
  {
    this.getInstance().position = position;
    this.getInstance().render(batch);
  }


  private animator: Animator;

  constructor() {
    super();
    this.setAllDirty();
    this.dimensions = new Dimensions(256, 256);
    this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);
    const bezier = new Bezier([
      new Vector(0.00, 0.0),
      new Vector(0.50, 0.0),
      new Vector(0.2, 1.0),
      new Vector(1.00, 1.0),
    ]);
    bezier.calcPoints(50);
    this.animator = new Animator(2000, bezier, true);
    //this.animator.bezier = bezier;
    //this.animator.isLoop = true;
    //this.animator.duration = 2000;

    this.animator.start();

  }

  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.animator.update(delta);
    this.isDirty = true;
  }

  render(batch: Batch): void {
    if (this.isDirty) {
      const myBatch = this.batch;
      myBatch.ctx.fillStyle = "white";
      myBatch.ctx.strokeStyle = "transparent";
      let c = new Rect(new Vector(), this.dimensions);
      myBatch.beginPath();
      c.render(myBatch);
      myBatch.fill();
      myBatch.closePath();
      myBatch.ctx.lineWidth = 5 * this.animator.getValue();
      myBatch.ctx.fillStyle = "transparent";
      myBatch.ctx.strokeStyle = "#424242";
      c = new Circle(new Vector(), 40 * this.animator.getValue());
      myBatch.beginPath();
      c.render(myBatch);
      myBatch.closePath();
      myBatch.stroke();
      let x = (1/this.animator.getValue());
      let clip = new RoundClip(this.dimensions, 128 * x);
      //clip.apply(myBatch, this.fbo);
      clip = new RoundClip(this.dimensions, 128);
      clip.apply(myBatch, this.fbo);
      this.isDirty = false;
    }
    const z = Math.min(batch.zoom * 2, 1);

    batch.drawImage(this.fbo.handle, this.position.x, this.position.y, this.dimensions.width * z, this.dimensions.height * z);
  }

}
