import {ActionHandler} from '../interfaces/actionHandler';
import {Hierarchy} from './hierarchy';

export abstract class Action {
  hierarchy: Hierarchy;

  constructor() {
    this.hierarchy = new Hierarchy();
  }

  abstract perform(actionHandler: ActionHandler);

  public shouldPerform(): boolean {
    return true;
  }
}

