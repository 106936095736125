import {ObjectLayer} from '../eleanor/objectLayer';
import {LoadingBlock} from './loadingBlock';
import {Event} from '../eleanor/tools/event';
import {Action} from '../eleanor/action/action';
import {Canvas} from '../eleanor/canvas';
import {ButtonGroup} from '../ui/buttonGroup';
import {Positioning} from '../ui/buttonGroup';
import {Button} from '../ui/button';
import {Vector} from '../eleanor/tools/vectors';
import {EmptyAction} from '../eleanor/action/emptyAction';
import {AddObjectAction} from '../eleanor/action/addObjectAction';
import {AddToUiAction} from '../eleanor/action/addToUiAction';
import {RemoveObjectAction} from '../eleanor/action/removeObjectAction';
import {RemoveFromUiAction} from '../eleanor/action/removeFromUiAction';
import {Mover} from './mover';
import {Actions} from "../eleanor/action/actions";
import {environment} from '../../../../../environments/environment';
import {RenderObject} from '../eleanor/renderObject';
import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {element} from 'protractor';
import {Block} from './block';


export class NodeLayer extends ObjectLayer {


  protected mover: Mover;

  constructor(canvas: Canvas, poolSize: number) {
    super(canvas, poolSize);
  }

  update(delta: number, zoom: number = 1) {
    super.update(delta, zoom);
    LoadingBlock.getInstance().update(delta);
  }


  preferredHoverCursor(): string {
    if (this.isContentHit()) {
      return 'pointer';
    }
    return 'grab';
  }

  preferredClickCursor(): string {
    if (this.isContentHit()) {
      return 'grabbing';
    }
    return 'grabbing';
  }

  public click(event: Event): Event {
    let result = super.click(event);
    if (this.hitElement) {

      if (!(this.selected.length === 0 || result.original.shiftKey)) {
        result.actions.addActions(this.unselect());
      }

      this.hitElement.click(result);

      if (result.original.shiftKey && this.hitElement.isSelected) {
        const buttons = [];
        for (const buttonGroup of this.hitElement.getButtons()) {
          buttons.push(buttonGroup);
        }
        result.actions.addAction(new RemoveFromUiAction(buttons));

        result.actions.addActions(this.hitElement.unselect());
        const newSelection = [];
        for (const element of this.selected) {
          if (element != this.hitElement) {
            newSelection.push(element);
          }
        }
        this.selected = newSelection;
        console.log('deselect new len: ' + this.selected.length);
        if (environment.readonly && this.selected.length === 1) {
          this.selected[0].unselect();
          this.selected[0].select();
          const newButtons = this.selected[0].createButtons();
          result.actions.addAction(new AddToUiAction(newButtons));
        }
      } else {


        this.selected.push(this.hitElement);
        this.hitElement.select();

        if (!environment.readonly && this.selected.length === 1) {  // todo: only show buttons with one node is selected?
          const currentAction = result.actions.last(); //[result.actions.length - 1];
          console.log(result.actions.length);
          console.log(currentAction.hierarchy.target);
          // todo: We either can create buttons for the hit node, or the whole hit block. Since we want to deselect, we make it block wide
          result.actions.addAction(new AddToUiAction(this.hitElement.createButtons()));
          // node-wide: result.push(new AddToUiAction(currentAction.hierarchy.target.createButtons()));
        } else {
          for (const element of this.selected) {
            result.actions.addActions(this.removeButtons(element));
          }
        }
      }

    } else {
      result.actions.addActions(this.unselect());
    }
    //console.log(this.selected);


    result.actions.setAllLayer(this);
    console.log(result.actions);

    return result;
  }

  doubleClick(event: Event): Event {
    let result = super.doubleClick(event);

    if (this.hitElement) {
      this.hitElement.doubleClick(result);
    }

    result.actions.setAllLayer(this);

    return result;
  }

  public unselect(): Array<Action> {
    let result = [];
    for (const element of this.selected) {

      if (this.hitElement !== element) {
        element.isSelected = false;
        //const buttons = [];
        //for (const buttonGroup of element.getButtons()) {
        //  buttons.push(buttonGroup);
        //}
        //result.push(new RemoveFromUiAction(buttons));
        result = result.concat(this.removeButtons(element));
        element.unselect();
      }
    }
    this.selected = [];

    return result;
  }

  public removeButtons(element: RenderObject): Array<Action> {
    const result = [];
    for (const buttonGroup of element.getButtons()) {
      result.push(new RemoveFromUiAction([buttonGroup]));
    }
    (<ComplexRenderObject>element).buttonGroup = [];
    return result;
  }


  blockCanvasMove(): boolean {
    return !!this.hitElement;
  }


  startMove(event: Event): Event {
    let result = super.startMove(event);
    if (this.hitElement) {
      if (!this.hitElement.isSelected) {
        result.actions.addActions(this.unselect());
      }
    }
    const movingObjects = this.selected.concat([this.hitElement]);
    this.mover = new Mover(movingObjects);
    result.actions.addAction(new AddObjectAction([this.mover], !environment.readonly));


    result.actions.setAllLayer(this);

    return result;
  }


  endMove(event: Event): Event {
    let result = super.endMove(event);
    if (this.mover) {
      result.actions.addAction(new RemoveObjectAction([this.mover]));

      if (this.hitElements.length > 1) {
        console.log('blocks need merge, please help with a small donation <3');
        // todo: merge blocks
      }
      //this.mover.remove();
      this.mover = null;
    }

    console.log('ended move');
    this.hasDragged = false;

    result.actions.setAllLayer(this);

    return result;
  }

  onMove(event: Event): Event {
    let result = super.onMove(event);
    if (this.anchor && this.mover) {
      this.hasDragged = true;
      //console.log("look at me");
      const moved = this.anchor.sub(result.center).div(new Vector(-this.getBatch().zoom, this.getBatch().zoom));
      //console.log(moved.str());

      this.mover.current = moved;

      if (this.hitElements.length > 1) {
        let sum = this.selected.includes(this.hitElement) ? 0 : this.hitElement.hitBox.height;
        for (const el of this.selected) {
          sum += el.hitBox.height;
        }
        (<Block>this.hitElements[this.hitElements.length - 2]).dragging(event, sum);
      }

    }


    result.actions.setAllLayer(this);

    return result;
  }



}
