import {FieldImporter} from './fieldImporter';
import * as $ from 'jquery';
import {Canvas} from '../eleanor/canvas';
import {environment} from '../../../../../environments/environment';
import {TfField} from '../../model/tfField';

export class OnlineFieldImporter extends FieldImporter{


  protected token: string;

  constructor(canvas: Canvas, token: string) {
    super(canvas);
    this.token = token;
  }



  public lessonOneOrRatherCircleOneHeHe() {
    this.embraceTheField();
  }


  embraceTheField(): void {

    console.log($);

    const self = this;

    $.ajax({

      method: 'POST',
      url: environment.fieldUrl + this.token,
      data: JSON.stringify({token: this.token}),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',

      success: function (response) {
        console.log(response);
        const field = response;
        self.data = field;
        self.sniffTheField();
      },
      error: function (response) {
        console.log('error');
        console.log(response);
      }


    });

  }


  sniffTheField(): TfField {
    const result = super.sniffTheField();
    this.lickTheField();
    return result;
  }
}
