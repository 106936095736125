import {RenderObject} from "./renderObject";
import {Layer} from "./layer";
import {Vector} from "./tools/vectors";
import {Dimensions} from "./tools/dimensions";
import {FrameBuffer} from "./frameBuffer";
import {FrameBufferHolder} from "./interfaces/frameBufferHolder";
import {Batch} from "./batch";
import {ButtonGroup} from '../ui/buttonGroup';
import {Action} from "./action/action";


export abstract class ComplexRenderObject extends RenderObject implements FrameBufferHolder
{

    public position: Vector;
    public dimensions: Dimensions;
    public fbo: FrameBuffer;
    public buttonGroup: Array<ButtonGroup>;


  constructor() {
    super();
    this.buttonGroup = [];
  }

  public get batch(): Batch
  {
      return new Batch(this.fbo.ctx, null, this.dimensions);
  }

  public setAllDirty()
  {
    this.setDirty();
  }
  public setDirty() {
    this.isDirty = true;
  }

  public createButtons(): Array<ButtonGroup> {
    return [];
  }
  public getButtons(): Array<ButtonGroup> {
    return this.buttonGroup;
  }


  unselect(): Array<Action> {
    super.unselect();
    this.buttonGroup = [];
    console.log("rm btn grp");
    return [];
  }
}
