import {TfGodObject} from './tfGodObject';
import {TfNode} from './tfNode';
import {TfConnection} from './tfConnection';
import {TfFieldItem} from './tfFieldItem';


export class TfNodeContainer extends TfGodObject {

  private _order: number;
  private _dataType: TfNodeContainerDataType;
  private _style: TfNodeContainerStyle;

  private _nodes: Array<TfNode>;
  private _connections: Array<TfConnection>;

  private _parent: TfFieldItem;


  constructor(id: string, order: number, dataType: TfNodeContainerDataType, style: TfNodeContainerStyle, parent) {
    super(id);
    this._order = order;
    this._dataType = dataType;
    this._style = style;
    this._nodes = [];
    this._connections = [];
    this._parent = parent;
  }


  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  get dataType(): TfNodeContainerDataType {
    return this._dataType;
  }

  set dataType(value: TfNodeContainerDataType) {
    this._dataType = value;
  }

  get style(): TfNodeContainerStyle {
    return this._style;
  }

  set style(value: TfNodeContainerStyle) {
    this._style = value;
  }

  get nodes(): Array<TfNode> {
    return this._nodes;
  }

  set nodes(value: Array<TfNode>) {
    this._nodes = value;
  }

  get connections(): Array<TfConnection> {
    return this._connections;
  }

  set connections(value: Array<TfConnection>) {
    this._connections = value;
  }


  get parent(): TfFieldItem {
    return this._parent;
  }

  set parent(value: TfFieldItem) {
    this._parent = value;
  }
}



export enum TfNodeContainerDataType {

  Text = 1,
  Image = 2,
  Website = 3,
  Field = 4,

}

export enum TfNodeContainerStyle {

  Zero = 0,
  One = 1,
  Two = 2,
  Title = Zero,
  Subtitle = Two,
  Paragraph = One,



}
