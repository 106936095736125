import {Action} from './action';
import {ActionHandler} from '../interfaces/actionHandler';
import {RenderObject} from '../renderObject';
import {ComplexRenderObject} from '../complexRenderObject';
import {Layer} from '../layer';


export class RemoveObjectAction extends Action {


  public objects: Array<RenderObject | ComplexRenderObject>;


  constructor(objects: Array<RenderObject | ComplexRenderObject>) {
    super();
    this.objects = objects;
  }

  perform(actionHandler: ActionHandler) {

    for (const object of this.objects) {
      object.remove();
    }

  }

  public targetLayer(): Layer {
    return this.hierarchy.layer;
  }
}
