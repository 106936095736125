



export class Font {

  public name: string;
  public names: Array<string>;
  public size: number;
  public style: string;
    public lineHeightSpacing: number;


    constructor(names?: Array<string>, size?: number, style?: string) {
        if (names) {
          this.names = names;
        }
        else {
          this.names = ['sans-serif'];
        }
        this.name = '';
        for (let i=0; i<this.names.length; i++) {
          this.name += this.names[i];
          if (i < this.names.length - 1)
            this.name += ', ';
        }
        this.size = size ? size : 20;
        this.style = style ? style : "normal";
        this.lineHeightSpacing = 1.3;
    }

    public getString(): string
    {
        return this.style + " " + this.size + "px " + this.name;
    }

    public height(): number
    {
        switch (this.names[0]) {

          case 'essence':
          case 'essence-regular':
          case 'essence-thin':
            return this.size * 1.3;
          case 'essence-bold':
            return this.size * .5;
          case 'roboto':
            return this.size * 1.3;
          case 'roboto-bold':
            return this.size * 1.3;

            default:
                return this.size;
        }
    }


    public onLoad()
    {

    }


}
