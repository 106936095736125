


import {Vector, Vector2, Vector3} from "./tools/vectors";
import {Bounds} from "./tools/bounds";
import {Dimensions} from "./tools/dimensions";
import {Canvas} from "./canvas";

export class Batch
{
  get isPixelPerfect(): boolean {
    return this._isPixelPerfect;
  }

  set isPixelPerfect(value: boolean) {
    this._isPixelPerfect = value;
  }

    private _position: Vector;
    private _dimensions: Dimensions;
    private _ctx: any;
    private _useAA: boolean;
    private _canvas: Canvas;
    private _zoom: number;
    private _isPixelPerfect: boolean;
    private _isBackgroundBatch: boolean;


    constructor(ctx: any, canvas?: Canvas, dimensions?: Dimensions, position?: Vector, zoom?: number) {
        this._ctx = ctx;
        if (canvas)
        {
            this._dimensions = canvas.dimensions;
            this._canvas = canvas;
            this._useAA = canvas.config.useAA;
            this._position = position ? position : canvas.position;
            this._zoom = zoom ? zoom : canvas.zoom;
            this._isPixelPerfect = this._canvas.config.usePixelPerfectPositions;
        }
        else
        {
            this._dimensions = dimensions ? dimensions : new Dimensions(0, 0);
            this._useAA = true;
            this._position = position ? position : new Vector(0, 0);
            this._zoom = zoom ? zoom : 1;
            this._isPixelPerfect = true;
        }

        this._isBackgroundBatch = false;
    }

    public translate(vec: Vector, z?: number): Vector
    {
        return new Vector(vec.x - this.position.x, vec.y - this.position.y, vec.z, vec.w);
    }

    public translateX(x: number): number
    {
        if (this._isPixelPerfect)
          return Math.floor((x + this.dimensions.width * .5 - this.position.x) * this._zoom);
        return (x + this.dimensions.width * .5 - this.position.x) * this._zoom;
    }
    public translateY(y: number): number
    {
        if (this._isPixelPerfect)
          return Math.floor((-y + this.dimensions.height * .5 - this.position.y) * this._zoom);
        return (-y + this.dimensions.height * .5 - this.position.y) * this._zoom;
    }
    public translateW(w: number): number
    {
      if (this._isPixelPerfect)
        return Math.floor(w * this._zoom);
      return w * this._zoom;
    }
    public translateH(h: number): number
    {
      if (this._isPixelPerfect)
        return Math.floor(h * this._zoom);
      return h * this._zoom;
    }

    public scale(v: number, z?: number)
    {
      return v * (z ? z : this._zoom);
    }


    public clearAll()
    {
        this.ctx.clearRect(0, 0, this.dimensions.width, this.dimensions.height);
    }




    public beginPath()
    {
        this.ctx.beginPath();
    }
    public closePath()
    {
        this.ctx.closePath();
    }
    public moveTo(x: number, y: number)
    {
        this.ctx.moveTo(this.translateX(x), this.translateY(y));
    }
    public bezierCurveTo(cp1x: number, cp1y: number, cp2x: number, cp2y: number, endx: number, endy: number)
    {
        this.ctx.bezierCurveTo(this.translateX(cp1x), this.translateY(cp1y), this.translateX(cp2x), this.translateY(cp2y), this.translateX(endx), this.translateY(endy));
    }
    public arc (x: number, y: number, r: number, startRad: number, endRad: number)
    {
        this.ctx.arc(this.translateX(x), this.translateY(y), r, startRad, endRad);
    }
    public lineTo(x: number, y: number)
    {
        this.ctx.lineTo(this.translateX(x), this.translateY(y));
    }
    public drawImage(image: any, x: number, y: number, w?: number, h?: number)
    {
        if (!w)
        {
            w = image.getAttribute("width");
            h = image.getAttribute("height");
        }
        const h1 = this._isPixelPerfect ? Math.floor(h) : h;
        const w1 = this._isPixelPerfect ? Math.floor(w) : w;
        const w2 = this._isPixelPerfect ? Math.floor(w * .5) : w * .5;
        const h2 = this._isPixelPerfect ? Math.floor(h * .5) : h * .5;
        this.ctx.drawImage(image, this.translateX(x) - w2, this.translateY(y) - h2, w1, h1);
        //else
        //    this.ctx.drawImage(image, this.translateX(x), this.translateY(y));
    }
    public drawLayer(image: any, x: number, y: number, w?: number, h?: number)
    {
      if (!w)
      {
        w = image.getAttribute("width");
        h = image.getAttribute("height");
      }
      const h1 = this._isPixelPerfect ? Math.floor(h) : h;
      const w1 = this._isPixelPerfect ? Math.floor(w) : w;
      const w2 = this._isPixelPerfect ? Math.floor(w * .5) : w * .5;
      const h2 = this._isPixelPerfect ? Math.floor(h * .5) : h * .5;
      const x1 = this._isPixelPerfect ? Math.floor(this.translateX(x) / this._zoom - w2) : this.translateX(x) / this._zoom - w2;
      const y1 = this._isPixelPerfect ? Math.floor(this.translateY(y) / this._zoom - h2) : this.translateY(y) / this._zoom - h2;
      this.ctx.drawImage(image, 0, 0, w1, h1); // todo: allow layer positioning (animationOffset) in canvas
      //else
      //    this.ctx.drawImage(image, this.translateX(x), this.translateY(y));
    }
    public clearRect(x: number, y: number, w: number, h: number)
    {
        this.ctx.clearRect(this.translateX(x), this.translateY(y), w, h);
    }
    public rect(x: number, y: number, w: number, h: number)
    {
        this.ctx.rect(this.translateX(x - w * .5), this.translateY(y + h * .5), w, h);
    }
    public save()
    {
        this.ctx.save();
    }
    public restore()
    {
        this.ctx.restore()
    }
    public fill()
    {
        this.ctx.fill();
    }
    public stroke()
    {
        this.ctx.stroke();
    }
    public clip()
    {
        this.ctx.clip();
    }
    public quadraticCurveTo (cpx: number, cpy: number, x: number, y: number)
    {
        this.ctx.quadraticCurveTo (this.translateX(cpx), this.translateY(cpy), this.translateX(x), this.translateY(y));
    }
    public measureText (s: string): TextMetrics
    {
        return this.ctx.measureText(s);
    }
    public textWidth (s: string): number
    {
        return this.ctx.measureText(s).width;
    }
    public textHeight (s: string): number
    {console.log(this.ctx.measureText(s));
        return this.ctx.measureText(s).height;
    }
    public fillText(content: string, x: number, y: number)
    {
        this.ctx.fillText(content, this.translateX(x), this.translateY(y));
    }





    get position(): Vector {
        return this._position;
    }

    set position(value: Vector) {
        this._position = value;
    }


    get dimensions(): Dimensions {
        return this._dimensions;
    }

    get ctx(): any {
        return this._ctx;
    }


    get useAA(): boolean {
        return this._useAA;
    }


  get zoom(): number {
    return this._zoom;
  }


  set zoom(value: number) {
    this._zoom = value;
  }


  get isBackgroundBatch(): boolean {
    return this._isBackgroundBatch;
  }

  set isBackgroundBatch(value: boolean) {
    this._isBackgroundBatch = value;
  }
}
