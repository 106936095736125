

export class Vector
{

    protected values: Array<number>;


    constructor(x?: number, y?: number, z?: number, w?: number) {
        this.values = [+x || 0, +y || 0, +z || 0, +w || 0];
    }

    public length(): number
    {
        let sum = 0;

        for (let v of this.values)
        {
            sum += v * v;
        }

        if (sum == 0)
            return 0;

        return Math.sqrt(sum);
    }

    public add(v: Vector): Vector
    {
        return new Vector(
            this.x + v.x,
            this.y + v.y,
            this.z + v.z,
            this.w + v.w
        );
    }

    public sub(v: Vector): Vector
    {
        return new Vector(
            this.x - v.x,
            this.y - v.y,
            this.z - v.z,
            this.w - v.w
        );
    }

    public mul(v: Vector): Vector
    {
        return new Vector(
            this.x * v.x,
            this.y * v.y,
            this.z * v.z,
            this.w * v.w
        );
    }

    public div(v: Vector): Vector
    {
        return new Vector(
            this.x / v.x,
            this.y / v.y,
            this.z / v.z,
            this.w / v.w
        );
    }

    public str(): string {
      return 'Vec[' + this.x + ', ' + this.y + ', ' + this.z + ', ' + this.w + ']';
    }

    get x(): number {return this.values[0];}
    get y(): number {return this.values[1];}
    get z(): number {return this.values[2];}
    get w(): number {return this.values[3];}


    set x(val: number) {this.values[0] = val;}
    set y(val: number) {this.values[1] = val;}
    set z(val: number) {this.values[2] = val;}
    set w(val: number) {this.values[3] = val;}

}

export class Vector2 extends Vector
{

    constructor(x: number, y: number) {
        super(x, y, 0, 0);
    }
}
export class Vector3 extends Vector
{
    constructor(x: number, y: number, z: number) {
        super(x, y, z, 0);
    }
}
export class Vector4 extends Vector
{

    constructor(x: number, y: number, z: number, w: number) {
        super(x, y, z, w);
    }
}
