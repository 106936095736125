export const list3 = {
    title: 'Todos of Basti.',
    items: [
        {   type: "todo",
            title: 'Plan Evaluation'
        },
        {   type: "todo",
            title: 'Conduct Interview'
        },
        {
            type: "todo",
            title: 'Finish Literature Review'
        }, {
            type: "todo",
            title: 'Submit timesheet'
        }]
};