import {Event} from "./event";
import {Vector} from "./vectors";
import {Batch} from "../batch";


export class MoveEvent extends Event {

  public before: Vector;
  public delta: Vector;


  constructor(original: MouseEvent, batch: Batch, before: Vector = new Vector(), delta: Vector = new Vector()) {
    super(original, batch);
    this.before = before;
    this.delta = delta;
  }
}
