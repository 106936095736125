export const list2 = {
    title: 'Todos of Chris.',
    items: [
        {
            type: "todo",
            title: 'Finish Literature Review'
        }, {
            type: "todo",
            title: 'Submit timesheet'
        },
        {
            type: "todo",
            title: 'Prepare conference poster'
        },
        {
            type: "todo",
            title: 'Submit paper by 28.02.19'
        }]
};