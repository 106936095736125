import {TfGodObject} from './tfGodObject';
import {TfPreviewImage} from './tfPreviewImage';


export class TfResource extends TfGodObject {

  private _binValue: any;
  private _filePath: string;
  private _url: string;
  private _uti: string;

  private _preview: TfPreviewImage;


  constructor(id: string, binValue: any, filePath: string, url: string, uti: string) {
    super(id);
    this._binValue = binValue;
    this._filePath = filePath;
    this._url = url;
    this._uti = uti;
  }


  get binValue(): any {
    return this._binValue;
  }

  set binValue(value: any) {
    this._binValue = value;
  }

  get filePath(): string {
    return this._filePath;
  }

  set filePath(value: string) {
    this._filePath = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get uti(): string {
    return this._uti;
  }

  set uti(value: string) {
    this._uti = value;
  }

  get preview(): TfPreviewImage {
    return this._preview;
  }

  set preview(value: TfPreviewImage) {
    this._preview = value;
  }
}
