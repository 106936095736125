


import {Layer} from "./layer";
import {Batch} from "./batch";
import {Renderable} from "./interfaces/renderable";
import {Canvas} from "./canvas";
import {RenderObject} from "./renderObject";
import {Log} from "./tools/log";
import {element} from 'protractor';
import {Vector} from './tools/vectors';
import {ComplexRenderObject} from './complexRenderObject';
import {Event} from "./tools/event";
import {Action} from "./action/action";
import {Actions} from "./action/actions";

export class ObjectLayer extends Layer
{

  protected hitElement: ComplexRenderObject;
  protected hitElements: Array<ComplexRenderObject>;

    constructor(canvas: Canvas, poolSize: number) {
        super(canvas, poolSize);
    }

    render(batch: Batch) {

        //this.clear();
        let b = this.getBatch();

        b.clearAll();

        for (let element of this.pool)
        {
          if (element.isVisible(b))
            element.render(b);
          if (element.isDirty)
            this.canvas.addToBackgroundRender(element.uuid);
        }
    }


  update(delta: number, zoom: number = 1) {
    super.update(delta, zoom);
    const myBatch = this.getBatch();
    this.hitElements = [];
    let e = null;
    let id = null;
    for (let i=0; i<this.pool.length; i++) {
      //console.log(this.canvas.cursor);
      const element = this.pool.get(i);
      if (element) {
        element.update(delta, zoom);
        if (element.isHit(myBatch, this.canvas.cursor)) {
          e = element;
          id = i;
          this.hitElements.push(e);
        }
      }
    }

    if (this.hitElements.length == 2) {
      //console.log(this.hitElements);
    }
    if (e) {
      const translatedCursor = this.canvas.cursor.sub(new Vector(myBatch.translateX(e.position.x), myBatch.translateY(e.position.y))).mul(new Vector(1, -1));
      this.top(id);
      if (e !== this.hitElement) {
        if (this.hitElement) {
          this.hitElement.hoverEnd();
        }
        e.hoverStart(translatedCursor);
      }
      else {
        e.hoverMove(translatedCursor);
      }
    }
    else if (this.hitElement) {
      this.hitElement.hoverEnd();
    }

    this.hitElement = e;
  }

  public top(id: number): void
  {
    const element = this.pool.get(id);
    this.pool.remove(id);
    this.pool.add(element);
  }



  isContentHit(): boolean {
    return !!this.hitElement;
  }


  click(event: Event): Event {
    let result =  super.click(event);
    if (this.hitElement) {
      this.hitElement.click(event);
    }
    return result;
  }

  doubleClick(event: Event): Event {
    let result = super.doubleClick(event);
    if (this.hitElement) {
      this.hitElement.doubleClick(event);
    }
    return result;
  }


}
