import {Field} from './field';
import {TfField} from '../../model/tfField';
import {TfFieldItem} from '../../model/tfFieldItem';
import {TfNodeContainer, TfNodeContainerDataType, TfNodeContainerStyle} from '../../model/tfNodeContainer';
import {TfConnection} from '../../model/tfConnection';
import {TfNode} from '../../model/tfNode';
import {Canvas} from '../eleanor/canvas';
import {FieldLayer} from './fieldLayer';
import {ConnectionLayer} from '../connection/connectionLayer';
import {NodeLayer} from '../node/nodeLayer';
import {Connection} from '../connection/connection';
import {Vector} from '../eleanor/tools/vectors';
import {Block} from '../node/block';
import {Builder} from '../node/builder';
import {TfResource} from '../../model/tfResource';
import {TfPreviewImage} from '../../model/tfPreviewImage';
import {bNode} from '../node/node';
import {Log} from '../eleanor/tools/log';
import {UiLayer} from '../eleanor/uiLayer';
import {ButtonGroup, Positioning} from '../ui/buttonGroup';
import {StaticUiLayer} from '../ui/staticUiLayer';
import {Button} from '../ui/button';
import {Action} from '../eleanor/action/action';
import {ActionHandler} from '../eleanor/interfaces/actionHandler';
import {Todo} from '../node/todo';


export class FieldImporter {



  protected host: string;
  protected token: string;
  protected user: string;
  protected password: string;

  protected callback: any;

  protected isImported: boolean;

  protected data: any;
  protected modelField: TfField;
  protected field: Field;

  protected modelToNode: any;

  protected canvas: Canvas;

  public createdCallback: any = false;
  public isFinished = false;


  constructor(canvas: Canvas) {
    this.canvas = canvas;
  }

  public model(): void {
    this.embraceTheField();
  }

  public view(): Field {
    return this.lickTheField();
  }

  public controller() {
    // todo: MVVM
  }


  public sniffTheField(): TfField {

    const field: TfField = new TfField(this.data._id, this.data.titleString);
    const items: Array<TfFieldItem> = [];
    const cachedConnectionData: Array<any> = [];
    const cachedNodes: Map<string, TfNode> = new Map<string, TfNode>();

    for (const item of this.data.items) {
      const fieldItem: TfFieldItem = new TfFieldItem(item._id, item.xPos, item.yPos, item.width, item.scale, item.diveScore * 1.5);
      if (!Field.minScale || Field.minScale > fieldItem.diveScore)
        Field.minScale = fieldItem.diveScore;

      Log.log("itm: " + fieldItem.diveScore);
      Log.log("fld: " + Field.minScale);

      for (const container of item.containers) {
        const nodeContainer: TfNodeContainer = new TfNodeContainer(container._id, container.order, container.dataType, container.style, item);

        for (const nodeData of container.nodes) {
          let resource = nodeData.resource;
          if (resource) {
            let preview = resource.preview;
            resource = new TfResource(resource._id, resource.binValue, resource.filePath, resource.url, resource.uti);

            if (preview) {
              preview = new TfPreviewImage(preview._id, preview.ratio, preview.thumbnailSmall, preview.thumbnailMedium);
              resource.preview = preview;
            }

          }
          let preview = nodeData.preview;
          if (preview) {
            preview = new TfPreviewImage(preview._id, preview.ratio, preview.thumbnailSmall, preview.thumbnailMedium);
          }
          const node: TfNode = new TfNode(nodeData._id, nodeData.stringValue, nodeData.type, resource, preview, nodeContainer);
          nodeContainer.nodes.push(node);
          cachedNodes.set(node._id, node);
        }

        for (const connectionData of container.connections) {
          connectionData.container = container;
          cachedConnectionData.push(connectionData);

        }

        fieldItem.containers.push(nodeContainer);
      }

      items.push(item);
    }

    console.log('cached connections', cachedConnectionData);
    for (const connectionData of cachedConnectionData) {

      let justDie = true;
      for (const node of connectionData.container.nodes) {
        if (node._id === connectionData.aNode) {
          justDie = false;
        }
      }
      if (justDie) continue;
      const aNode: TfNode = cachedNodes.get(connectionData.aNode);
      const bNode: TfNode = cachedNodes.get(connectionData.bNode);
      const connection: TfConnection = new TfConnection(connectionData._id, connectionData.label, aNode, bNode);
      (<TfNodeContainer>connectionData.container).connections.push(connection);
      field.connections.push(connection);
    }

    for (const item of items) {
      field.addItem(item);
    }

    this.modelField = field;

    Log.info(field, this);


    return field;

  }

  public get(): Field {
    if (this.isImported) return this.field;
    throw new Error('Tried to get field from unfinished FieldImporter. Be sure to date the field and be fucking patient you piece of shit');
  }

  public lickTheField(): Field {
    const field: Field = new Field();
    const fieldLayer: FieldLayer = new FieldLayer(this.canvas, 1, field);

    this.modelToNode = {};

    const connectionLayer = new ConnectionLayer(this.canvas, 500);
    this.canvas.addLayer(connectionLayer);


    const nodeLayer = new NodeLayer(this.canvas, 100);

    for (const item of this.modelField.items) {
      const block: Block = Builder.buildBlock(new Vector(item.xPos, -item.yPos));

      block.scale = item.diveScore;
      block.minWidth = item.width;
      if (item.width != 252)
        block.maxWidth = item.width;

      for (const container of item.containers) {



          for (const modelNode of container.nodes) {
            let node = null;
            switch (container.dataType) {
              case TfNodeContainerDataType.Text:
                node = Builder.buildTitleNode(modelNode.stringValue, container.style);
                break;
              case TfNodeContainerDataType.Image:
                node = Builder.buildImageNode(modelNode.resource.preview.thumbnailMedium, modelNode.resource.preview.ratio || 1.3);
                break;
              case TfNodeContainerDataType.Website:
                node = Builder.buildWebsiteNode(modelNode.preview.thumbnailMedium, modelNode.preview.ratio || 1.3, modelNode.stringValue, container.style);
                break;
              case TfNodeContainerDataType.Field:
                node = Builder.buildTitleNode('FieldNode', TfNodeContainerStyle.Paragraph);
                break;

              default:
                node = Builder.buildTitleNode('error', TfNodeContainerStyle.Paragraph);

            }
            if (node) {
              const todo = modelNode.todoValue;
              //console.log('todo val', todo);
              //const todo = Math.random() < .033 ? null : (Math.random() > .5);
              if (todo !== undefined && todo !== null) {
                node.todo = new Todo(todo, node);
              }
              block.addNode(node);
            }
            this.modelToNode[modelNode._id] = node;
          }


      }

      nodeLayer.addObject(block);
    }

    this.canvas.addLayer(nodeLayer);

console.log('connections', this.modelField.connections)
    for (const modelConnection of this.modelField.connections) {
      if ((!modelConnection.aNode || !modelConnection.bNode) ||  (!modelConnection.aNode.parent || !modelConnection.bNode.parent)) {
        continue;  
      }
      const aItem = modelConnection.aNode.parent.parent;
      const bItem = modelConnection.bNode.parent.parent;
      const connection = new Connection(this.modelToNode[modelConnection.aNode._id], this.modelToNode[modelConnection.bNode._id]);
      connectionLayer.addObject(connection);
    }


    const uiLayer = new UiLayer(this.canvas, 100);
    uiLayer.name = 'ui';
    this.canvas.addLayer(uiLayer);

    fieldLayer.hitOrMiss = false;
    this.canvas.addLayer(fieldLayer);

    this.washTheField();
    field.modelToNode = this.modelToNode;
    console.log('licked');
    this.field = field;


    if (this.createdCallback) {
      this.createdCallback(this);
    }
    this.isFinished = true;
    return field;
  }

  public washTheField(): void {
    console.log('wash the field');
    const layer = new StaticUiLayer(this.canvas, 10);
    layer.name = 'static_ui';
    const zoomInButton = new ButtonGroup(
      new Vector(0, this.canvas.height * -.5),
      [
        new Button(
          '+',
          new class extends Action {
            perform(actionHandler: ActionHandler) {
              console.log('zoomin');
              actionHandler.smoothZoomIn();
            }
          }
        ),
        new Button(
          '-',
          new class extends Action {
            perform(actionHandler: ActionHandler) {
              console.log('zoomout');
              actionHandler.smoothZoomOut();
            }
          }
        )
      ],
      0,
      Positioning.Top,
      Positioning.Top
    );
    zoomInButton.animator.start();
    layer.addObject(zoomInButton);
    this.canvas.addLayer(layer);

  }

  public dateTheField(): void {

  }

  public beTheField(): void {

  }

  public embraceTheField(): void {




  }


  public lessonOneOrRatherCircleOneHeHe() {

  }

  public getModel(): TfField {
    if (this.modelField) return this.modelField;
    throw new Error('Tried to get field model from unfinished FieldImporter. Be sure to sniff the field and be fucking patient you piece of shit');
  }
  public getField(): Field {
    if (this.field) return this.field;
    throw new Error('Tried to get field view from unfinished FieldImporter. Be sure to lick the field and be fucking patient you piece of shit');
  }


}
