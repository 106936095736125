// MonGod Object

import {TfUniqueObject} from './tfUniqueObject';

export class TfGodObject extends TfUniqueObject {

  constructor(id: string) {
    super(id);
  }
}
