import {TfGodObject} from './tfGodObject';
import {TfNodeContainer} from './tfNodeContainer';
import {TfResource} from './tfResource';
import {TfPreviewImage} from './tfPreviewImage';


export class TfNode extends TfGodObject {

  private _stringValue;
  private _type: TfNodeType;
  private _resource: TfResource;
  private _parent: TfNodeContainer;
  private _preview: TfPreviewImage;
  private _todoValue: boolean;


  constructor(id: string, stringValue, type: TfNodeType, resource, preview, parent, todoValue = null) {
    super(id);
    this._stringValue = stringValue;
    this._type = type;
    this._resource = resource;
    this._parent = parent;
    this._todoValue = todoValue;
  }


  get stringValue() {
    return this._stringValue;
  }

  set stringValue(value) {
    this._stringValue = value;
  }

  get type(): TfNodeType {
    return this._type;
  }

  set type(value: TfNodeType) {
    this._type = value;
  }

  get resource() {
    return this._resource;
  }

  set resource(value) {
    this._resource = value;
  }

  get preview() {
    return this._preview;
  }

  set preview(value) {
    this._preview = value;
  }

  get parent(): TfNodeContainer {
    return this._parent;
  }

  set parent(value: TfNodeContainer) {
    this._parent = value;
  }


  get todoValue(): boolean {
    return this._todoValue;
  }

  set todoValue(value: boolean) {
    this._todoValue = value;
  }
}


export enum TfNodeType {
  Title = 0,
  Text = 1,
  Image = 2,
  Website = 3
}

