import {Dimensions} from "../tools/dimensions";


export class Line {

    public content: string;
    public dimensions: Dimensions;


    constructor(content: string, dimensions: Dimensions) {
        this.content = content;
        this.dimensions = dimensions;
    }

    public get width(): number
    {
        return this.dimensions.width;
    }
    public get width2(): number
    {
        return this.dimensions.width * .5;
    }
    public get height(): number
    {
        return this.dimensions.height;
    }
    public get height2(): number
    {
        return this.dimensions.height * .5;
    }
}