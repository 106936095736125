import {Action} from './action';
import {ActionHandler} from '../interfaces/actionHandler';


export class EmptyAction extends Action {

  protected text: string;

  constructor(text?: string) {
    super();
    this.text = text;
  }

  perform(actionHandler: ActionHandler) {
    if (this.text) {
      console.log('Event: ' + this.text);
    }
  }

}
