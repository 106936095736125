import {Clip} from "../eleanor/tools/clip";
import {Circle} from "../eleanor/shapes/circle";
import {Vector} from "../eleanor/tools/vectors";
import {Batch} from "../eleanor/batch";
import {Dimensions} from "../eleanor/tools/dimensions";


export class HoleClip extends Clip {

    public dimensions: Dimensions;
    public yPos: number;


    constructor(yPos: number, dimensions: Dimensions) {
        super();
        this.yPos = yPos;
        this.dimensions = dimensions;
    }

    protected clip(batch: Batch): void
    {
      const r = 4;
        let c = new Circle(new Vector(0, 0), 25);
        //c.render(batch);
        //batch.closePath();
        //batch.beginPath();
        c = new Circle(new Vector(this.dimensions.width * .5, this.yPos), r);
        c.render(batch);
        //batch.closePath();
        c = new Circle(new Vector(this.dimensions.width * -.5, this.yPos), r);
        c.render(batch);
        //batch.closePath();
        /*c = new Circle(new Vector(-150, 100), 50);
        c.render(batch);
        batch.closePath();
        c = new Circle(new Vector(-100, 150), 80);
        c.render(batch);
        batch.closePath();*/
    }

}
