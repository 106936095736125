import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {Dimensions} from '../eleanor/tools/dimensions';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Text} from '../eleanor/text/text';
import {Vector} from '../eleanor/tools/vectors';
import {Font} from '../eleanor/text/font';
import {Animator} from '../eleanor/tools/animator';
import {EaseOutBezier} from '../eleanor/tools/bezier';
import {Action} from '../eleanor/action/action';
import {Event} from '../eleanor/tools/event';
import {EmptyAction} from '../eleanor/action/emptyAction';


export class Button extends ComplexRenderObject {

  public text: Text;
  public hoverAnimator: Animator;
  public action: Action;

  constructor(label: string, action: Action, dimensions?: Dimensions) {
    super();
    this.dimensions = dimensions || new Dimensions(100, 48);
    this.action = action;
    this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);
    this.text = new Text(
      label,
      new Vector(0, -4),
      this.dimensions,
      new Font(['essence', 'sans-serif'], 24),
      'center',
      'center',
      this.dimensions
    );
    this.text.color = 'black';

    this.hoverAnimator = new Animator(600, EaseOutBezier.instance());
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.text.update(delta);
    this.hoverAnimator.update(delta);
  }

  render(batch: Batch): void {
    if (this.isDirty || !this.hoverAnimator.isFinished) {
      const myBatch = this.batch;
      myBatch.clearAll();
      myBatch.beginPath();
      myBatch.rect(0, 0, this.width, this.height);
      myBatch.ctx.fillStyle = '#C2C2C2';
      myBatch.fill();
      myBatch.closePath();
      myBatch.beginPath();
      myBatch.rect(0, 0, this.width, this.height);
      myBatch.ctx.fillStyle = 'rgba(61, 61, 61, ' + (this.hoverAnimator.getValue() * 1) + ')';
      myBatch.fill();
      myBatch.closePath();
      this.text.render(myBatch);
    }
    batch.drawImage(this.fbo.handle, this.position.x, this.position.y, this.width, this.height);

  }

  hoverStart() {
    super.hoverStart();
    this.hoverAnimator.isFinished = false;
    this.hoverAnimator.forward();
    this.text.font = new Font(['essence', 'sans-serif'], 24, 'bold');
    this.text.color = '#FFCC00';
    this.text.setDirty();
    //console.log("hover started" + this.hoverAnimator.getValue());
  }

  hoverEnd() {
    super.hoverEnd();
    this.hoverAnimator.isFinished = false;
    this.hoverAnimator.reverse();
    this.text.font = new Font(['essence', 'sans-serif'], 24);
    this.text.color = 'black';
    this.text.setDirty();
  }


  click(event: Event): Event {
    const result = super.click(event);
    console.log('adding btn action:');
    const add = this.action || new EmptyAction();
    console.log(add);
    result.actions.addAction(add);
    return result;
  }


  setDirty(): void {
    super.setDirty();
    this.fbo.setDimensions(this.dimensions);
  }
}
