import {ObjectLayer} from '../eleanor/objectLayer';
import {Field} from './field';
import {Canvas} from '../eleanor/canvas';


export class FieldLayer extends ObjectLayer {

  protected field: Field;


  constructor(canvas: Canvas, poolSize: number, field: Field) {
    super(canvas, poolSize);
    this.field = field;
  }
}
