import {RenderObject} from "../renderObject";
import {Batch} from "../batch";
import {Dimensions} from "../tools/dimensions";
import {Vector} from "../tools/vectors";


export class Rect extends RenderObject {
    constructor(position: Vector, dimensions: Dimensions) {
        super();
        this.position = position;
        this.dimensions = dimensions;
    }

    render(batch: Batch): void
    {
        batch.rect(this.position.x, this.position.y, this.dimensions.width, this.dimensions.height);
    }
}

export class RoundRect extends RenderObject {


    public radi: Vector;


    constructor(position: Vector, dimensions: Dimensions, radi?: Vector) {
        super();
        this.position = position;
        this.dimensions = dimensions;
        if (radi)
            this.radi = radi;
        else
            this.radi = new Vector();
    }

    render(batch: Batch): void
    {
        batch.moveTo(-this.width2 + this.radi.x, this.height2);

        batch.lineTo(this.width2 - this.radi.y, this.height2);
        batch.quadraticCurveTo(this.width2, this.height2, this.width2, this.height2 - this.radi.y);

        batch.lineTo(this.width2, -this.height2 + this.radi.z);
        batch.quadraticCurveTo(this.width2, -this.height2, this.width2 - this.radi.z, -this.height2);

        batch.lineTo(-this.width2 + this.radi.w, -this.height2);
        batch.quadraticCurveTo(-this.width2, -this.height2, -this.width2, -this.height2 + this.radi.w);

        batch.lineTo(-this.width2, this.height2 - this.radi.x);
        batch.quadraticCurveTo(-this.width2, this.height2, -this.width2 + this.radi.x, this.height2);
    }

}