import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {Block} from './block';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {CompactRenderer} from './compactRenderer';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Shadow} from './shadow';


export class CompactBlock extends ComplexRenderObject {

  protected block: Block;
  public compacts: Array<CompactRenderer> = [];

  public shadow: Shadow;


  constructor(block: Block) {
    super();
    this.block = block;
    this.dimensions = new Dimensions(0 , 0);
    this.shadow = new Shadow(this, this.block.shadowColor, this.block.shadowOffset.add(new Vector(0, 0, 10)));

  }

  public recalc() {
    const containers = this.block.getContainers();
    const radius = containers.length === 1 ? 50 : 100;
    this.dimensions = new Dimensions(radius * 2 + 25, radius * 2 + 25);
    this.position = this.block.position;
    this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);

    this.shadow.dimensions = this.dimensions.copy();

    let last = 0;
    const step = (Math.PI * 2) / containers.length;
    let i = 0;
    this.compacts = [];
    for (const container of containers) {
      i++;
      let compact = container.compact;
      if (i == containers.length) {
        compact.angle = new Vector(last, Math.PI * 2);
      }
      else {
        compact.angle = new Vector(last, last + step);
      }
      compact.usedAngle = last + step * .5;  // Lol nice :D - Finally :.)
      last += step;

      compact.radius = radius;
      this.compacts.push(compact);
    }

    const batch = this.batch;
    batch.clearAll();

    for (const compact of this.compacts) {
      compact.render(batch);
    }

    /*this.fbo.ctx.shadowColor = this.block.shadowColor; //"rgba(0,0,0,0.8)";
    this.fbo.ctx.shadowBlur = 10;
    batch.ctx.shadowOffsetX = this.block.shadowOffset.x;
    batch.ctx.shadowOffsetY = this.block.shadowOffset.y;

    batch.drawImage(this.fbo.handle, 0, 0);

    batch.ctx.shadowColor = "transparent";
    batch.ctx.shadowBlur = 0;
    batch.ctx.shadowOffsetX = 0;
    batch.ctx.shadowOffsetY = 0;*/

  }

  render(batch: Batch): void {
    if (this.shadow.isDirty) {
      this.shadow.render(this.batch);
    }
    this.shadow.performRender(batch, this.position.x, this.position.y, batch.zoom * this.dimensions.width, batch.zoom * this.dimensions.height);
    batch.drawImage(this.fbo.handle, this.position.x, this.position.y, batch.zoom * this.dimensions.width, batch.zoom * this.dimensions.height);
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.shadow.update(delta, zoom);
  }
}
