import {Batch} from '../batch';
import {Vector} from './vectors';
import {Actions} from "../action/actions";


export class Event {

  public original: MouseEvent | any;

  public batch: Batch;

  public actions: Actions;

  constructor(original: MouseEvent, batch: Batch) {
    this.original = original;
    this.batch = batch;
    this.actions = new Actions();
  }

  public translatedCursor(position: Vector) {
    const cursor = new Vector(this.original.clientX, this.original.clientY);
    const translatedCursor = cursor.sub(new Vector(this.batch.translateX(position.x), this.batch.translateY(position.y))).mul(new Vector(1, -1));
    return translatedCursor;
  }

  public isLeftClick() {
    return this.original.button === 0;
  }
  public isRightClick() {
    return this.original.button === 2;
  }

  get center(): Vector {
    return this.original.center ? new Vector(this.original.center.x, this.original.center.y) : new Vector(this.original.clientX, this.original.clientY);
  }

}
