import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {SidebarComponent} from './src/app/sidebar/sidebar.component';
import { ModalComponent } from './src/app/modal/modal.component';


export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
    // override hammerjs default configuration
    //'swipe': { direction: Hammer.DIRECTION_ALL  }
    'pan': { threshold: .5 },
    'pinch': { enabled: true }
  }
}

@NgModule({
  declarations: [
    AppComponent,
      SidebarComponent,
      ModalComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      [
        { path: 'field/:token', component: AppComponent},
        { path: 'field/:token/params/:params', component: AppComponent},
        //{ path: 'field/:token/nonqueryparameterswtfangularijustwanttousegetparameterswhyudodis2me/:params', component: AppComponent},
        //  { path: 'field/:token/norouteroutletsoangularthinkswtfheisusingarouterwithoutroutingfunctionality/:params', component: AppComponent},
        { path: '', component: AppComponent} ],
      { enableTracing: true } // <-- debugging purposes only
    )

  ],
  providers:    [ {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
