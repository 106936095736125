import {bNode} from './node';
import {Text} from '../eleanor/text/text';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Batch} from '../eleanor/batch';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Font} from '../eleanor/text/font';
import {Log} from '../eleanor/tools/log';
import {Block} from './block';


export class TitleNode extends bNode
{




  protected text: Text;
  protected fontSize: number;
  protected style: string;
  protected textAlign: string;
  protected originalWidth: number;

  protected minContentDimensions: Dimensions;
  protected maxContentDimensions: Dimensions;

  protected maxHeight: number = 500;

  public content: string;

  private _icon: string = 'b';


  constructor(content: string, fontSize=24, textAlign='left', style='') {
    super();
    this.content = content;
    this.fontSize = fontSize;
    this.textAlign = textAlign;
    this.style = style;
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    if(this.text.font.size !== this.fontSize / zoom)
    {
      //this.setDirty();
      //this.text.isDirty = true;
      //this.text.changed = true;
      //this.text.font.size = this.fontSize / zoom;
    }
    this.text.update(delta);
  }

  render(batch: Batch): void {
    super.render(batch);
  }

  calculatedHeight(): number {
      return Math.max(this.text.calculatedHeight, 48);
  }

  protected renderContent(batch: Batch) {
    if (this.text.isDirty) Log.log('render node ' + this.dimensions.str + ' (block: ' + this.block.contentDimensions.str + ')');
    this.text.render(batch);
    //Log.log(this.dimensions);
    //batch.drawImage(this.fbo.handle, 0, 0, this.dimensions.width, this.dimensions.height);
  }



  public setAllDirty()
  {
    super.setAllDirty();
    //Log.log("on text dirt");
    this.text.isDirty = true;
    this.text.changed = true;
    this.text.adjust();
    if (this.todo) {
      this.todo.setAllDirty();
    }

    //this.dimensions = this.text.dimensions;
    //this.fbo.setDimensions(this.dimensions);

  }

  public setContent(content: string) {
    this.content = content;
    this.text.content = this.content;
    this.initialize(this.block);
    this.block.updateDimensions();
    this.block.setAllDirty();
  }


  public initialize(block: Block)
  {
    super.initialize(block);

    /*let c = "😅 すか ypl блять ézzänĉ";
    const x = Math.random() * 3;
    for (let i = 0; i < x; i++)
    {
      if (Math.random() > .3)
        c += "Abcfg Hello Wurlg";
      if (Math.random() > .3)
        c += "おいしいマンコ";
      if (Math.random() > .3)
        c += "😍😍 3√a 😍 ";
      if (Math.random() > .3)
        c += "😏∑ ";
      if (Math.random() > .3)
        c += "😘😘 ";
      if (Math.random() > .3)
        c += "ay ∰ boi";
      if (Math.random() > .3)
        c += "";
    }*/

    this.text = new Text(
      this.content,  // "すか блять ezzenz",
      new Vector(0, 0),
      this.textDimensions,
      new Font(["roboto", 'sans-serif'], this.fontSize, this.style),
      this.textAlign, "center",
      new Dimensions(0, block.minHeight),
    );
    this.text.autoWidth = true;
    this.text.adjust();
    this.text.minDimensions = this.text.actDimensions;
    const paddingTop = Math.max(this.fontSize - 20, 4);
    this.text.position = new Vector(this.todo ? this.todo.width2 + 4 : 0, -paddingTop);

    this.dimensions = new Dimensions(this.dimensions.width, this.text.dimensions.height);
    if (!this.fbo)
      this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);
    this.originalWidth = this.width;

    //Log.log(block);
    //Log.log(this);
  }

  get textDimensions(): Dimensions {
    return new Dimensions(this.block.maxWidth - (this.todo ? this.todo.width : 0), this.maxHeight);
  }


  applyBlockWidth(): boolean {
    this.text.renderDimensions = new Dimensions(this.textDimensions.width, this.text.renderDimensions.height);
    this.text.minDimensions = this.text.renderDimensions.copy();
    //this.dimensions = this.text.renderDimensions.copy().padding(new Vector(0, 0));  // removed due to fixed node width
    this.dimensions = new Dimensions(this.blockWidth(), this.text.renderDimensions.height);
    if (this.todo) {
      //this.dimensions = new Dimensions(this.blockWidth(), this.dimensions.height);
    }
    if (!this.fbo)
      this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);
    this.text.adjust();

    return this.text.renderDimensions.height != this.dimensions.height;

  }


  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
    this.compact.icon = this.icon;
  }


  blockWidth(): number {
    return this.originalWidth;
  }

  typeKey(): string {
    return 'title';
  }
}
