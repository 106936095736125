import {Canvas} from '../canvas';
import {Layer} from '../layer';
import {RenderObject} from '../renderObject';


export class Hierarchy {

  public canvas: Canvas;
  public layer: Layer;
  public object: RenderObject;
  public child: RenderObject;

  public hasObject(): boolean {
    return !!this.object;
  }
  public hasChild(): boolean {
    return !!this.child;
  }

  public get target(): RenderObject {
    return this.child || this.object;
  }


}
