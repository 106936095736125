import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {Button} from './button';
import {Animator} from '../eleanor/tools/animator';
import {Shadow} from '../node/shadow';
import {RoundClip} from '../node/roundClip';
import {EaseOutBezier} from '../eleanor/tools/bezier';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Action} from '../eleanor/action/action';
import {Event} from '../eleanor/tools/event';


export class ButtonGroup extends ComplexRenderObject {


  public buttons: Array<Button>;
  public buttonDirection: number;  // 0: ltr, 1: ttb
  public positioning: Positioning;
  public animator: Animator;

  public shadow: Shadow;

  public clip: RoundClip;

  public padding = new Vector(16, 16);
  public innerDimension: Dimensions;

  public animationOffset: Vector;
  public offset: Vector;


  constructor(targetPosition: Vector, buttons: Array<Button>, buttonDirection: number, positioning: Positioning, affinity?: Positioning) {
    super();
    this.buttons = buttons;
    this.buttonDirection = buttonDirection;
    this.positioning = positioning;
    this.position = targetPosition;
    this.animator = new Animator(600, EaseOutBezier.instance());
    this.animator.start();
    this.dimensions = new Dimensions(0, 0);
    this.shadow = new Shadow(this, 'rgb(0, 0, 0, .5)', new Vector(2, 2, 10));  // old shadow: 'rgb(128, 128, 128)'
    //this.shadow.tintAnimator.start();
    //this.shadow.tintAnimator.update(10000);
    this.recalc();
    this.animationOffset = new Vector(50, 50);

    switch (affinity) {
      case Positioning.Top:
        this.offset = new Vector(0, this.height2);
        break;
      case Positioning.Left:
        this.offset = new Vector(this.width2, 0);
        break;
      case Positioning.Bottom:
        this.offset = new Vector(0, -this.height2);
        break;
      case Positioning.Right:
        this.offset = new Vector(-this.width2, 0);
        break;
      default:
        this.offset = new Vector(0, 0);
    }

    this.position = this.position.add(this.offset);

    this.setAllDirty();
  }

  render(batch: Batch): void {

    const myBatch = this.batch;
    if (this.getIsDirty()) {
      myBatch.clearAll();
      //myBatch.ctx.globalAlpha = this.animator.getValue();

      for (const button of this.buttons) {
        button.render(myBatch);
        //console.log(button.position.str());
      }

      this.clip.apply(myBatch, this.fbo);
      this.isDirty = false;
    }
    //this.shadow.setDirty();
    this.shadow.render(myBatch);
    //myBatch.ctx.globalAlpha = 1;
    //const globalCompositeOperation = batch.ctx.globalCompositeOperation;
    batch.ctx.globalAlpha = this.animator.getValue();
    //batch.ctx.globalCompositeOperation = 'destination-atop';
    //console.log(this.animator.getValue());
    this.shadow.performRender(batch, this.renderPosition.x, this.renderPosition.y, this.width, this.height);
    batch.drawImage(this.fbo.handle, this.renderPosition.x, this.renderPosition.y, this.width, this.height);
    batch.ctx.globalAlpha = 1;
    //batch.ctx.globalCompositeOperation = globalCompositeOperation;

  }

  get renderPosition(): Vector {
    switch (this.positioning) {
      case Positioning.Top:
        return this.position.add(this.animationOffset.mul(new Vector(0, this.animator.getValueInverse())));
      case Positioning.Left:
        return this.position.add(this.animationOffset.mul(new Vector(this.animator.getValueInverse(), 0)));
      case Positioning.Bottom:
        return this.position.add(this.animationOffset.mul(new Vector(0, -this.animator.getValueInverse())));
      case Positioning.Right:
        return this.position.add(this.animationOffset.mul(new Vector(-this.animator.getValueInverse(), 0)));
    }
  }

  getScale(batch: Batch): number {
    return 1;
  }

  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.animator.update(delta);
    for (const button of this.buttons) {
      button.update(delta, zoom);
    }
  }

  public recalc() {
    let width = 0;
    let height = 0;
    for (const button of this.buttons) {
      if (this.buttonDirection === 0) {  // horizontal
        width += button.width;
        height = Math.max(height, button.height);
      }
      else if (this.buttonDirection === 1) {  // vertical
        height += button.height;
        width = Math.max(width, button.width);
      }
    }

    this.innerDimension = new Dimensions(width, height);
    this.dimensions = this.innerDimension.padding(this.padding);
    this.shadow.dimensions = this.dimensions.copy();
    this.clip = new RoundClip(this.innerDimension, 4);
    this.shadow.setDirty();
    this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);

    //console.log(this.dimensions);

    let position = this.buttonDirection === 0 ? -this.innerDimension.width2 : -this.innerDimension.height2;
    for (const button of this.buttons) {
      let x = 0;
      let y = 0;
      if (this.buttonDirection === 0) {
        x = position + button.width2;
        position += button.width;
        button.dimensions = new Dimensions(button.dimensions.width, height);
      } else if (this.buttonDirection === 1) {
        y = position + button.height2;
        position += button.height;
        button.dimensions = new Dimensions(width, button.dimensions.height);
      }

      button.setDirty();
      button.position = new Vector(x, y);
    }
  }


  getIsDirty(): boolean {
    for (const button of this.buttons) {
      if (!button.hoverAnimator.isFinished) {
        return true;
      }
    }
    return this.isDirty;
  }

  setAllDirty(): void {
    super.setAllDirty();
    for (const button of this.buttons) {
      button.setAllDirty();
    }
  }


  hoverStart(cursor: Vector) {
    super.hoverStart(cursor);
    for (const button of this.buttons) {
      if (button.position.x - button.width2 >= cursor.x &&
          button.position.y - button.height2 >= cursor.y &&
          button.position.x + button.width2 <= cursor.x &&
          button.position.y + button.height2 <= cursor.y
      ) {
        button.hoverStart();
        this.setDirty();
      }
    }
  }

  hoverEnd(cursor: Vector) {
    super.hoverEnd(cursor);
    for (const button of this.buttons) {
      button.hoverEnd();
    }
    this.setDirty();
  }

  hoverMove(cursor: Vector) {
    super.hoverMove(cursor);
    //console.log('hover');
    //console.log(cursor.str());
    for (const button of this.buttons) {
      //console.log(button.position.str());
      //console.log(button.dimensions.str);
      if (
        button.position.x - button.width2 <= cursor.x &&
        button.position.y - button.height2 <= cursor.y &&
        button.position.x + button.width2 >= cursor.x &&
        button.position.y + button.height2 >= cursor.y
      ) {
        button.hoverStart();
        this.setDirty();
      }
      else {
        button.hoverEnd();
        this.setDirty();
      }
    }
  }


  onRemove() {
    super.onRemove();
    this.animator.isFinished = false;
    this.animator.reverse();
  }


  get toRemove(): boolean {
    return this._toRemove && this.animator.isFinished;
  }


  click(event: Event): Event {
    let result = super.click(event);
    const button = this.hitButton(result.translatedCursor(this.position));
    //console.log(event.translatedCursor(this.position).str());
    if (button) {
      console.log('btn hit');
      console.log(result.actions.last());
      button.click(result);
      console.log(result.actions.last());
    }
    return result;
  }

  public hitButton(cursor: Vector): Button | null {
    for (const button of this.buttons) {
      //console.log(button.position.str());
      //console.log(button.dimensions.str);
      if (
        button.position.x - button.width2 <= cursor.x &&
        button.position.y - button.height2 <= cursor.y &&
        button.position.x + button.width2 >= cursor.x &&
        button.position.y + button.height2 >= cursor.y
      ) {
        return button;
      }
    }
    return null;
  }
}

export enum Positioning {
  Top,
  Right,
  Bottom,
  Left
}
