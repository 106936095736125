import {bNode} from '../node/node';
import {TfNode} from '../../model/tfNode';


export class Field {

  public static minScale: number; // todo: rm -rf this hack

  public viewToModel: Array<TfNode>;
  public modelToNode: any;

}
