import {RenderObject} from "../renderObject";
import {Batch} from "../batch";
import {Vector, Vector4} from "../tools/vectors";


export class BezierLine extends RenderObject{


    public start: Vector4;
    public end: Vector4;

    // Vectors are:
    //      x: x of start/end
    //      y: y of start/end
    //      z: x animationOffset of control point for start/end
    //      w: y animationOffset of control point for start/end

    constructor(start: Vector4, end: Vector4) {
        super();
        this.start = start;
        this.end = end;
    }

    render(batch: Batch): void {


        let start = this.start;
        let end = this.end;
        batch.moveTo(start.x, start.y);
        batch.bezierCurveTo(
            start.x + start.z, start.y + start.w,
            end.x + end.z, end.y + end.w,
            end.x, end.y
        );


    }

}
