import {Vector} from './vectors';


export class Color {

  public value: Vector;

  static fromRGBA(r: number, g: number, b: number, a:number = 255): Color {
    return new Color(new Vector(Color.from255(r), Color.from255(g), Color.from255(b), Color.from255(a)));
  }

  constructor(value: Vector) {
    this.value = value;
  }

  public static to255(v: number): number {
    return Math.floor(v * 255);
  }

  public static from255(v: number): number {
    return v / 255;
  }

  public string(): string {
    return 'rgba(' + Color.to255(this.r) + ', ' + Color.to255(this.g) + ', ' + Color.to255(this.b) + ', ' + Color.to255(this.a) + ')';
  }
  public rgb(): string {
    return 'rgba(' + Color.to255(this.r) + ', ' + Color.to255(this.g) + ', ' + Color.to255(this.b) + ')';
  }

  public opposite(): Color {
    return new Color(new Vector(-1, -1, -1, 0).add(this.value).mul(new Vector(-1, -1, -1, 1)));
  }

  public get r(): number {
    return this.value.x;
  }
  public get g(): number {
    return this.value.y;
  }
  public get b(): number {
    return this.value.z;
  }
  public get a(): number {
    return this.value.w;
  }



}
