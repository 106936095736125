import {bNode} from './node';
import {Block} from './block';
import {Vector} from '../eleanor/tools/vectors';
import {TitleNode} from './titleNode';
import {ImageNode} from './imageNode';
import {TfNodeContainerStyle} from '../../model/tfNodeContainer';
import {WebsiteNode} from './websiteNode';


export class Builder {


  public static buildTitleNode(content: string, style: TfNodeContainerStyle): bNode
  {
    let fontSize = 18;
    let fontStyle = '';
    let textAlign = 'left';
    let icon = 'b';

    switch (style) {
      case TfNodeContainerStyle.Title:
        fontStyle = 'bold';
        fontSize = 26;
        icon = '';
        break;
      case TfNodeContainerStyle.Subtitle:
        //fontStyle = 'italic';
        fontSize = 22;
        icon = '';
        break;
      case TfNodeContainerStyle.Paragraph:
        textAlign = 'left';
        icon = '';
        break;
    }

    //fontSize = 24;
    //textAlign = 'left';
    //fontStyle = '';

    const node: TitleNode = new TitleNode(content, fontSize, textAlign, fontStyle); // todo: think through, implement
    node.icon = icon;
    return node;
  }

  public static buildImageNode(content: string, ratio: number): bNode
  {
    const node: bNode = new ImageNode(content, ratio); // todo: think through, implement
    return node;
  }
  public static buildWebsiteNode(content: string, ratio: number, data: string, style: TfNodeContainerStyle): bNode
  {
    const node: bNode = new WebsiteNode(content, ratio, data, style); // todo: think through, implement
    return node;
  }


  public static buildBlock(position: Vector): Block
  {
    const block: Block = new Block(position);

    return block;
  }



}
