import {TfGodObject} from './tfGodObject';
import {TfResource} from './tfResource';


export class TfPreviewImage extends TfGodObject {

  private _ratio: number;
  private _thumbnailSmall: string;
  private _thumbnailMedium: string;


  constructor(id: string, ratio: number, thumbnailSmall: string, thumbnailMedium: string) {
    super(id);
    this._ratio = ratio;
    this._thumbnailSmall = thumbnailSmall;
    this._thumbnailMedium = thumbnailMedium;
  }


  get ratio(): number {
    return this._ratio;
  }

  set ratio(value: number) {
    this._ratio = value;
  }

  get thumbnailSmall(): string {
    return this._thumbnailSmall;
  }

  set thumbnailSmall(value: string) {
    this._thumbnailSmall = value;
  }

  get thumbnailMedium(): string {
    return this._thumbnailMedium;
  }

  set thumbnailMedium(value: string) {
    this._thumbnailMedium = value;
  }
}
