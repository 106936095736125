import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {Blockable} from '../eleanor/interfaces/blockable';
import {bNode} from './node';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
export class NodeDummy extends bNode {


  constructor(dimensions: Dimensions) {
    super();
    this.dimensions = dimensions;
    this.fbo.setDimensions(this.dimensions);
  }

  protected renderContent(batch: Batch) {
    batch.beginPath();
    batch.rect(0, 0, this.width, this.height);
    batch.closePath();
    batch.ctx.fillStyle = '#aaaaaa';
    batch.fill();
    console.log(this.dimensions.str);
  }


  protected updateDimensions(): void {

  }

  typeKey(): string {
    return 'dummy';
  }
}
