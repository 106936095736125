import {bNode, HoleDirection} from './node';
import {Batch} from '../eleanor/batch';
import {Block} from './block';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {CompactRenderer} from './compactRenderer';


export abstract class WrapperNode extends bNode {


  protected node: bNode;


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.node.update(delta, zoom);
  }

  render(batch: Batch): void {
    super.render(batch);
    this.node.render(batch);
  }

  calculatedHeight(): number {
    return this.node.calculatedHeight();
  }

  setDirty(): void {
    super.setDirty();
    this.node.setDirty();
  }

  protected updateDimensions(): void {
    super.updateDimensions();
  }

  initialize(block: Block): void {
    super.initialize(block);
    if (this.todo) {
      this.node.todo = this.todo;
    }
    this.node.initialize(block);
    this.setAllDirty();
  }

  setY(value: number): void {
    super.setY(value);
    this.node.setY(value);
  }

  applyBlockWidth(): boolean {
    return this.node.applyBlockWidth();
  }

  isFirst(): boolean {
    return super.isFirst();
  }

  isLast(): boolean {
    return super.isLast();
  }

  worldPosition(batch): Vector {
    return this.node.worldPosition(batch);
  }

  holeVectorFor(node: bNode, zoom: number): Vector {
    return this.node.holeVectorFor(node, zoom);
  }

  clearHoles(): void {
    super.clearHoles();
    this.node.clearHoles();
  }

  getHoles(): Array<HoleDirection> {
    return this.node.getHoles();
  }

  get batch(): Batch {
    return this.node.batch;
  }

  setAllDirty(): void {
    super.setAllDirty();
    this.node.setAllDirty();
  }

  getScale(batch: Batch): number {
    return this.node.getScale(batch);
  }

  get width(): number {
    return this.node.width;
  }

  get height(): number {
    return this.node.height;
  }

  get width2(): number {
    return this.node.width2;
  }

  get height2(): number {
    return this.node.height2;
  }

  get dimensions(): Dimensions {
    return this.node.dimensions;
  }

  set dimensions(value: Dimensions) {
    this.node.dimensions = value;
  }


  get compact(): CompactRenderer {
    return this.node.compact;
  }

  set compact(value: CompactRenderer) {
    this.node.compact = value;
  }

  isVisible(batch: Batch): boolean {
    return this.node.isVisible(batch);
  }

  isHit(batch: Batch, position: Vector): boolean {
    return this.node.isHit(batch, position);
  }

  get hitBox(): Dimensions {
    return this.node.hitBox;
  }

  set hitBox(value: Dimensions) {
    this.node.hitBox = value;
  }

  toString(): string {
    return this.node.toString();
  }

  toLocaleString(): string {
    return this.node.toLocaleString();
  }

  valueOf(): Object {
    return this.node.valueOf();
  }

  /* todo: reactivate
  hasOwnProperty(v: PropertyKey): boolean {
    return this.node.hasOwnProperty(v);
  }*/

  isPrototypeOf(v: Object): boolean {
    return this.node.isPrototypeOf(v);
  }

  propertyIsEnumerable(v: PropertyKey): boolean {
    return this.node.propertyIsEnumerable(v);
  }


  typeKey(): string {
    return this.node.typeKey();
  }
}
