import {Batch} from "../batch";


export abstract class Clip
{




    public apply(batch: Batch): void
    {

        batch.ctx.save();


        batch.beginPath();
        this.clip(batch);
        batch.closePath();

        batch.ctx.fillStyle = "transparent";
        batch.ctx.fill();


        batch.clip();
        batch.clearAll();
        batch.restore();
        //batch.closePath();
    }


    protected abstract clip(batch: Batch): void;



}
