import {TfGodObject} from './tfGodObject';
import {TfNodeContainer} from './tfNodeContainer';


export class TfFieldItem extends TfGodObject {

  private _xPos: number;
  private _yPos: number;
  private _width: number;
  private _scale: number;
  private _diveScore: number;

  private _containers: Array<TfNodeContainer>;


  constructor(id: string, xPos: number, yPos: number, width: number, scale: number, diveScore: number) {
    super(id);
    this._xPos = xPos;
    this._yPos = yPos;
    this._width = width;
    this._scale = scale;
    this._diveScore = diveScore;
    this._containers = [];
  }


  get xPos(): number {
    return this._xPos;
  }

  set xPos(value: number) {
    this._xPos = value;
  }

  get yPos(): number {
    return this._yPos;
  }

  set yPos(value: number) {
    this._yPos = value;
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  get scale(): number {
    return this._scale;
  }

  set scale(value: number) {
    this._scale = value;
  }

  get diveScore(): number {
    return this._diveScore;
  }

  set diveScore(value: number) {
    this._diveScore = value;
  }

  get containers(): Array<TfNodeContainer> {
    return this._containers;
  }

  set containers(value: Array<TfNodeContainer>) {
    this._containers = value;
  }
}
