import {TfNode} from '../../model/tfNode';
import {RenderObject} from '../../view/eleanor/renderObject';
import {bNode} from '../../view/node/node';


export abstract class Content {

  public model: TfNode;
  public view: bNode;


  constructor(model: TfNode, view: bNode) {
    this.model = model;
    this.view = view;
  }

  public abstract content(): string;
  public abstract contentReadOnly(): string;
  public abstract save(): boolean;


}
