

export class BMath
{


    static facs: Array<number> = BMath.facs = [ 1 ];

    static binomArray(k: number): Array<number>
    {
        let res = [];

        for (let i=0; i<=k; i++)
        {
            res.push(BMath.binom(k, i));
        }

        return res;
    }


    static binom(k: number, n: number): number
    {
        return BMath.fac(k) / (BMath.fac(k - n) * BMath.fac(n));
    }


    static fac(n: number): number
    {
        if (BMath.facs.length > n)
        {
            //console.log("fac already calculated for " + n + ": " + BMath.facs[n]);
            return BMath.facs[n];
        }

        //console.log("calc for " + n);
        let index = BMath.facs.length - 1;
        let curr = BMath.facs[index];

        while (n >= BMath.facs.length)
        {
            let next = curr * (index + 1);

            BMath.facs.push(next);

            index++;
            curr = next;

            //console.log("next is " + index + ": " + next);
        }
        //console.log("returning: " + BMath.facs[index]);
        return BMath.facs[index];
    }


    static round(n: number, places: number = 0)
    {
      return Math.round(n * Math.pow(10, places)) / Math.pow(10, places);
    }

}
