


import {Batch} from "../batch";
import {Vector} from "../tools/vectors";
import {RenderObject} from "../renderObject";
import {Dimensions} from "../tools/dimensions";

export class Circle extends RenderObject
{

    private radius: number;

    constructor(position: Vector, radius: number) {
        super();
        this.position = position;
        this.radius = radius;
        this.dimensions = new Dimensions(radius * 2, radius * 2);
    }


    public update(delta: number): void {
        super.update(delta);
    }

    render(batch: Batch): void {
        //batch.moveTo(this.position.x, this.position.y);
        batch.arc(this.position.x, this.position.y, this.radius,0,2*Math.PI);
        //super.render(batch);
    }
}