

import {FrameBufferHolder} from "./interfaces/frameBufferHolder";
import {Log} from "./tools/log";
import {Dimensions} from './tools/dimensions';

export class FrameBuffer {

    private _handle: any;
    private _ctx: any;
    private _owner: FrameBufferHolder;


    constructor(owner: FrameBufferHolder) {
        this._owner = owner;
        this._handle = document.createElement('canvas');
        this._ctx = this._handle.getContext("2d");
        this._handle.setAttribute("style", "display: none;");
    }


    get handle(): any {
        return this._handle;
    }

    get ctx(): any {
        return this._ctx;
    }

    get owner(): FrameBufferHolder {
        return this._owner;
    }

    public setDimensions(dim: Dimensions, min?: Dimensions) {
      if (!min)
        min = dim;
      this.handle.setAttribute("width", "" + Math.max(dim.width, min.width));
      this.handle.setAttribute("height", "" + Math.max(dim.height, min.height));
    }
}


