import {Vector} from '../eleanor/tools/vectors';
import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {RenderObject} from '../eleanor/renderObject';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Animator} from '../eleanor/tools/animator';
import {BezierLine} from '../eleanor/shapes/bezierLine';
import {Bezier, EaseOutBezier} from '../eleanor/tools/bezier';


export class Shadow extends ComplexRenderObject{

  public target: ComplexRenderObject;
  public color: string;
  public tint: string;
  public tintFbo: FrameBuffer;

  public tintAnimator: Animator;


  constructor(target: ComplexRenderObject, color: string, position: Vector) {
    super();
    this.target = target;
    this.color = color;
    this.position = position;
    this.dimensions = this.target.dimensions.copy();

    this.tint = "#FFCC00";

    this.tintAnimator = new Animator(600, EaseOutBezier.instance());

    this.setDirty();
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.tintAnimator.update(delta);
  }

  render(batch: Batch): void {

    if (this.isDirty) {

      this.fbo = new FrameBuffer(this);
      this.fbo.setDimensions(this.dimensions);
      const myBatch: Batch = this.batch;

      myBatch.ctx.shadowColor = this.color; //"rgba(0,0,0,0.8)";
      myBatch.ctx.shadowOffsetX = this.position.x - this.target.dimensions.width;
      myBatch.ctx.shadowOffsetY = this.position.y;
      myBatch.ctx.shadowBlur = this.position.z;

      myBatch.drawImage(this.target.fbo.handle, this.target.dimensions.width, 0);


      const tintFbo = new FrameBuffer(this);
      tintFbo.setDimensions(this.dimensions);
      const tintBatch = new Batch(tintFbo.ctx);
      tintBatch.ctx.fillStyle = this.tint;
      tintBatch.beginPath();
      tintBatch.rect(this.dimensions.width2, -this.dimensions.height2, this.dimensions.width, this.dimensions.height);
      tintBatch.fill();
      tintBatch.closePath();

      tintBatch.ctx.globalCompositeOperation = "destination-atop";
      tintBatch.drawImage(this.fbo.handle, this.dimensions.width2, -this.dimensions.height2);

      this.tintFbo = tintFbo;

      this.isDirty = false;
    }

    //batch.drawImage(this.fbo.handle, this.position.x, this.position.y);

  }

  public performRender(batch: Batch, x: number, y: number, w: number, h: number) {
    const a = this.tintAnimator.getValue();
    //console.log(a);
    const initialALpha = batch.ctx.globalAlpha;
    batch.ctx.globalAlpha = (1 - a) * initialALpha;
    batch.drawImage(this.fbo.handle, x, y, w, h);
    batch.ctx.globalAlpha = a * initialALpha;
    batch.drawImage(this.tintFbo.handle, x, y, w, h);
    batch.ctx.globalAlpha = initialALpha;
  }

  public startTint() {
    this.tintAnimator.isFinished = false;
    this.tintAnimator.forward();
    //console.log(this.tintAnimator);
  }
  public stopTint() {
    this.tintAnimator.isFinished = false;
    this.tintAnimator.reverse();
    //console.log(this.tintAnimator);
  }

}
