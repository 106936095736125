import {Action} from "./action";
import {Canvas} from "../canvas";
import {Layer} from "../layer";
import {RenderObject} from "../renderObject";
import {EmptyAction} from "./emptyAction";


export class Actions {

  protected actions: Array<Action>;
  protected index: number;


  constructor(actions: Array<Action> = [new EmptyAction()]) {
    this.actions = actions;
    this.index = 0;
  }


  public addAction(action: Action) {
    this.actions.push(action);
  }
  public addActions(actions: Array<Action>) {
    this.actions = this.actions.concat(actions);
  }
  public merge(actions: Actions) {
    this.addActions(actions.actions);
  }

  public current(): Action {
    if (this.index >= this.actions.length)
      return null;
    return this.actions[this.index];
  }

  public next(): Action {
    if (this.index >= this.actions.length)
      return null;
    return this.actions[this.index++];
  }

  public last(): Action {
    if (this.actions.length == 0)
      return null;
    return this.actions[this.actions.length - 1];
  }

  public all(): Array<Action> {
    return this.actions;
  }

  public setAllCanvas(canvas: Canvas) {
    for (const action of this.actions) {
      action.hierarchy.canvas = canvas;
    }
  }
  public setAllLayer(layer: Layer) {
    for (const action of this.actions) {
      action.hierarchy.layer = layer;
    }

  }
  public setAllObject(object: RenderObject) {
    for (const action of this.actions) {
      action.hierarchy.object = object;
    }
  }
  public setAllChild(child: RenderObject) {
    for (const action of this.actions) {
      action.hierarchy.child = child;
    }
  }

  public get length(): number {
    return this.actions.length;
  }


}


