import {Bezier} from "./bezier";


export class Animator {

    protected progress: number = 0;
    private _duration: number = 1000;
    protected timeCount: number = 0;
    private _bezier: Bezier;
    private _isLoop: boolean = false;
    private _isReverse: boolean = false;
    private _startDelay: number = 0;
    protected endDelay: number = 0;
    protected direction: number = 0;
    private _isFinished: boolean = false;
    protected prevProgress = 0;

    protected isBlockingInput: boolean = false;


  constructor(duration: number, bezier: Bezier, isLoop: boolean = false, isReverse: boolean = false, startDelay: number = 0, endDelay: number = 0, direction: number = 0) {
    this._duration = duration;
    this._bezier = bezier;
    this._isLoop = isLoop;
    this._isReverse = isReverse;
    this._startDelay = startDelay;
    this.endDelay = endDelay;
    this.direction = direction;
  }

  public start() {
      this.direction = 1;
    }

    public update (delta: number) {

      this.prevProgress = this.progress;
      if (this.isFinished) {
        return;
      }

      this.timeCount += delta * this.direction;
      this.progress = Math.min(Math.max(0, this.timeCount - this._startDelay), this._duration);

      /*console.log("----");
      console.log("delta " + delta);
      console.log("count " + this.timeCount);
      console.log("prog " + this.progress);*/
      //console.log("val " + this.getValue());

      if ((this.direction > 0 && this.progress === this.duration) || (this.direction < 0 && this.progress === 0)) {
        this._isFinished = true;
      }

      if (this._isFinished) {
        //console.log("finished");
        if (this.direction < 0)
          this.timeCount = 0;
        else
          this.timeCount = this.duration + this._startDelay;
        if (this._isLoop) {
          this._isFinished = false;
          this.direction = 0 - this.direction;
        }
        else {
          this.direction = 0;
        }
      }
    }
    public getValue(): number
    {
      if (this._isReverse)
        return this.bezier.getValue(1 / this.getTimeValue());
      return this.bezier.getValue(this.getTimeValue());
    }
    public getValueInverse(): number {
      return 1 - this.getValue();
    }

    public getTimeValue(): number {
      return this.progress / this._duration;
    }

    public forward() {
      this.direction = 1;
    }
    public reverse() {
      this.direction = -1;
    }
    public stop() {
      this.direction = 0;
    }

    public restart() {
      this.progress = 0;
      this.isFinished = false;
    }

  get duration(): number {
    return this._duration;
  }

  set duration(value: number) {
    this._duration = value;
  }

  get bezier(): Bezier {
    return this._bezier;
  }

  set bezier(value: Bezier) {
    this._bezier = value;
  }

  get isLoop(): boolean {
    return this._isLoop;
  }

  set isLoop(value: boolean) {
    this._isLoop = value;
  }

  get isReverse(): boolean {
    return this._isReverse;
  }

  set isReverse(value: boolean) {
    this._isReverse = value;
  }


  get isFinished(): boolean {
    return this._isFinished;
  }

  set isFinished(value: boolean) {
    this._isFinished = value;
  }

  get hasChanged(): boolean {
    return this.progress != this.prevProgress;
  }
}
