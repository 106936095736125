


import {Log} from "./log";

export class Pool<T> implements IterableIterator<T>
{
    public elements: Array<T>;
    public initialLength: number;
    public length: number;  // the maximum length of the current elements array
    public index: number;  // the current index for insertion of new elements
    public pointer: number;  // pointer for iteration
    public size: number;  // the actual count of elements inside the array. Increased @ add, and Decreased @ remove if a non-null element is removed


    constructor(initialLength: number) {
        this.initialLength = initialLength;
        this.elements = new Array(initialLength);
        this.length = initialLength;
        this.index = 0;
        this.pointer = 0;
        this.size = 0;
    }

    public add(element: T): number
    {
        if (this.index >= this.length)
        {
            this.enlarge();
        }

        this.elements[this.index] = element;
        this.index++;
        this.size++;

        return this.index - 1;
    }

    public remove(i: number): void
    {
        if (i == this.index - 1)
        {
            this.index--;
        }
        if (this.elements[i] !== null)
            this.size--;
        this.elements[i] = null;
    }

    public enlarge(): void
    {
        let old: Array<T> = this.elements;
        this.elements = new Array(this.length * 2);
        let j = 0;
        for (let i=0; i < old.length; i++)
        {
            if (old[i] !== null)
            {
                this.elements[j] = old[i];
                j++
            }
        }
        this.index = j;

        this.length = this.length * 2;
    }

    public next(): IteratorResult<T> {
        if (this.pointer == this.index)
        {
            this.pointer = 0;
            return {
                done: true,
                value: null
            };
        }
        for (let i=this.pointer; i < this.index; i++)
        {
            if (this.elements[i] !== null)
                return {
                    done: false,
                    value: this.elements[this.pointer++]
                };
            this.pointer++;
        }

        return {
            done: true,
            value: null
        }
    }

    [Symbol.iterator](): IterableIterator<T> {
        return this;
    }

    public get(index: number): T
    {
        return this.elements[index];
    }

}