import {ImageNode} from './imageNode';
import {bNode} from './node';
import {Batch} from '../eleanor/batch';
import {TitleNode} from './titleNode';
import {Builder} from './builder';
import {TfNodeContainerStyle} from '../../model/tfNodeContainer';
import {Dimensions} from '../eleanor/tools/dimensions';
import {WrapperNode} from './wrapperNode';
import {Event} from '../eleanor/tools/event';
import {LinkAction} from '../eleanor/action/linkAction';
import {Action} from '../eleanor/action/action';
import {environment} from "../../../../../environments/environment";


export class WebsiteNode extends WrapperNode {

  protected data: any;
  protected style: TfNodeContainerStyle;


  constructor(preview: string, ratio: number, data: string, style: TfNodeContainerStyle) {
    super();
    const dataObj = JSON.parse(data);
    this.data = dataObj;
    const str: string = '🔗 ' + dataObj.title;
    this.style = style;

    if (this.isImage()) {
      this.node = Builder.buildImageNode(preview, ratio);
    }
    else {
      this.node = Builder.buildTitleNode(str, TfNodeContainerStyle.Subtitle);
    }
    this.compact.icon = '🔗';
  }

  protected renderContent(batch: Batch) {

    //this.node.render(batch);

  }

  public isImage(): boolean {
    return this.style === TfNodeContainerStyle.Zero;
  }


  click(event: Event): Event {
    const result = super.click(event);

    if (this.block.isSelected && (result.original.ctrlKey || result.original.metaKey)) {
      const action = new LinkAction(this.data.url, '_blank');
      action.hierarchy.child = this;
      result.actions.addAction(action);
    }
    else {
      console.log("website node clicked without ctrl or cmd key. What do you expect me to do?");
    }

    return result;
  }


  doubleClick(event: Event): Event {
    const result = super.click(event);
    if (environment.readonly) {
      const action = new LinkAction(this.data.url, '_blank');
      action.hierarchy.child = this;
      result.actions.addAction(action);
    }
    return result;
  }

  typeKey(): string {
    return 'website';
  }
}
