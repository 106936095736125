import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ModalEvent} from '../view/events/modalEvent';
import * as $ from 'jquery';
import {Content} from './content/content';
import {Title} from './content/title';
import {bNode} from '../view/node/node';
import {environment} from '../../../../environments/environment';  // for bad times
import {Image} from './content/image';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() public modalEvents: Observable<ModalEvent>;
  public isVisible = false;
  public content: Content;
  public classes: String = '';



  constructor() {
  }

  ngOnInit() {
    this.modalEvents.subscribe((event) => {
      console.log('modal received event: ', event);
      this.content = null;
      switch (event.type) {
        case 'title':
          this.content = new Title(event.modelItem, <bNode> event.viewItem);
          this.classes = '';
          break;
        case 'image':
          this.content = new Image(event.modelItem, <bNode> event.viewItem);
          this.classes = 'image';
          break;
      }
      if (this.content) {
        console.log('creating modal. Content:', this.content);
        document.getElementById('modalContent').innerHTML = environment.readonly ? this.content.contentReadOnly() : this.content.content();
        document.getElementById('modalContent').className = 'modal ' + this.classes;
        this.show();
      } else {
        console.warn('Tried to create modal, but no type was detected:', event.type);
      }
    });
  }

  public discard() {

  }

  public accept() {
    if (this.content && !environment.readonly) {
      this.content.save();
    }
    this.hide();
  }

  public dismiss() {
    this.accept();
    this.hide();
  }

  public show() {
    this.isVisible = true;
  }
  public hide() {
    this.isVisible = false;
  }

  public onWrapperClick(event) {
    if ($(event.target).hasClass('modalWrapper')) {
      this.dismiss();
    }
    console.log(event);
  }

}
