import {Action} from './action';
import {ActionHandler} from '../interfaces/actionHandler';
import {RenderObject} from '../renderObject';
import {ModalConfig} from './modalConfig';


export class ModalAction extends Action {

  public target: RenderObject;
  public type: string;


  constructor(target: RenderObject, type: string) {
    super();
    this.target = target;
    this.type = type;
  }

  perform(actionHandler: ActionHandler) {
    actionHandler.openModal(new ModalConfig(
      this.target,
      this.type
    ));
  }

}
