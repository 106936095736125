import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {bNode, HoleDirection} from './node';
import {BlockType} from './blockType';
import {Blockable} from '../eleanor/interfaces/blockable';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {HoleClip} from './holeClip';
import {RoundClip} from './roundClip';
import {LoadingBlock} from './loadingBlock';
import {Field} from '../field/field';
import {Circle} from '../eleanor/shapes/circle';
import {Log} from '../eleanor/tools/log';
import {TitleNode} from './titleNode';
import {CompactRenderer} from './compactRenderer';
import {CompactBlock} from './compactBlock';
import {Event} from '../eleanor/tools/event';
import {Shadow} from './shadow';
import {Action} from '../eleanor/action/action';
import {ButtonGroup} from '../ui/buttonGroup';
import {Actions} from "../eleanor/action/actions";
import {MoveEvent} from "../eleanor/tools/moveEvent";
import {NodeDummy} from './nodeDummy';

export class Block extends ComplexRenderObject implements Blockable {


  protected containers: Array<Blockable>;
  protected type: BlockType;
  protected padding: Vector;
  protected innerPadding = new Vector(32);
  private _contentDimensions: Dimensions;

  public minWidth: number = 252;
  public minHeight: number = 48;
  public maxWidth: number = 252;
  public maxHeight: number = 1000;

  public tinyColor: string = '#3D3D3D';

  public dotRadius = 6;

  public shadow: Shadow;

  public backgroundColor: string = "#fff";
  public shadowColor: string = "rgba(128, 128, 128, 1)";
  public shadowOffset: Vector = new Vector(2, 2);

  public minDimensions: Dimensions = new Dimensions(this.minWidth, 48);
  public maxDimensions: Dimensions = new Dimensions(this.maxWidth, 512);

  public bgRender: boolean = true;
  compact: CompactRenderer;
  public compactBlock: CompactBlock;

  public isActive: boolean = false;

  constructor(position: Vector) {
    super();

    this.containers = [];
    this.position = position;
    this.isDirty = true;
    this.padding = new Vector(24, 24);

    this.updateDimensions();

    this.shadow = new Shadow(this, this.shadowColor, this.shadowOffset.add(new Vector(0, 0, 5)));

    this.compactBlock = new CompactBlock(this);

    //this.containers.push(new TitleNode(this));
    //this.scale = Math.random() * 4;
    //this.scale = 1;
    //Log.log("height " + this.calculatedHeight());


  }

  public update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.shadow.update(delta, zoom);
    this.compactBlock.update(delta, zoom);

    //this.isDirty = true;
    const newHeight = this.calculatedHeight();  // todo: add hasChanged and dont always calculate
    const newWidth = this.calculatedWidth();
    //Log.log(this.contentDimensions.height + " - " + newHeight);
    if (this._contentDimensions.height !== newHeight || this._contentDimensions.width !== newWidth) {
      //Log.log("new height: " + newHeight + " old " + this.dimensions.padding(this.padding).height);
      this.updateDimensions();
    }

    for (const container of this.containers) {
      container.update(delta, zoom);
    }

    const batch = this.batch;
    batch.zoom = zoom;
    const scale = this.getScale(batch);
    const type = this.getScaleType(scale);
    switch (type) {
      case Scales.Tiny:
        this.hitBox = new Dimensions(this.dotRadius * 2, this.dotRadius * 2);
        break;
      case Scales.Compact:
        this.hitBox = this.compactBlock.dimensions.copy();
        for (const buttonGroup of this.getButtons()) {
          buttonGroup.isHidden = true;
        }
        break;
      case Scales.Normal:
        this.hitBox = this.contentDimensionsWithPadding.copy();
        for (const buttonGroup of this.getButtons()) {
          buttonGroup.isHidden = false;
        }
    }
  }

  public updateDimensions() {
    /*if (this.containers.length == 3 && (<TitleNode>this.containers[0]).content.includes('Version ')) {  // todo: remove this shit
      Log.log('block w/ image dimens: ' + this.contentDimensions.str);
      Log.log(': ' + this.containers[0].calculatedHeight());
      Log.log(': ' + this.containers[1].calculatedHeight());
      Log.log(': ' + this.containers[2].calculatedHeight());

    }*/
    this._contentDimensions = new Dimensions(this.calculatedWidth(), this.calculatedHeight());
    //console.log('calc width: ', this.calculatedWidth());
    this.dimensions = this._contentDimensions.padding(this.padding).padding(this.innerPadding);

    let recalc = false;

    for (const container of this.containers) {
      recalc = recalc || container.applyBlockWidth();
    }

    //console.log('update dimensions');

    if (recalc) {
      Log.log("recalc dimens");
      this.updateDimensions();
    }

    if (this.shadow) {
      this.shadow.dimensions = this.dimensions.copy();
      this.shadow.setDirty();
    }

    this.fbo = new FrameBuffer(this);
    //this.fbo.handle.setAttribute("width", "" + (this.dimensions.width));
    //this.fbo.handle.setAttribute("height", "" + (this.dimensions.height));
    this.fbo.setDimensions(this.dimensions);
    this.updateContainerPositions();
  }

  render(batch: Batch): void {
    let small = this.dimensions.padding(new Vector(-this.padding.x, -this.padding.y));
    if (this.isDirty && !batch.isBackgroundBatch && this.bgRender) {
      LoadingBlock.draw(batch, this.position);
      return;
    }
    if (this.isDirty) {

      let myBatch = this.batch;
      //this.fbo.ctx.clearRect(0, 0, this.dimensions.width + this.padding.x, this.dimensions.height);

      //myBatch.clearAll();


      //batch.beginPath();

      //myBatch.beginPath();
      // Bg
      myBatch.ctx.fillStyle = this.backgroundColor;
      myBatch.rect(0, 0, this.dimensions.width, this.dimensions.height);
      myBatch.fill();
      //myBatch.ctx.fillRect(0, 0, 5000, 5000);
      //Log.log("x: " + myBatch.translateX(0) + ", y: " + myBatch.translateY(0));
      //myBatch.ctx.fillRect(0, 0, 100, 100);
      //myBatch.drawImage(this.i,0, 0, small.width, small.height);

      //myBatch.closePath();


      for (const container of this.containers) {
        container.render(myBatch);
        // Cut
        let hole: HoleClip = new HoleClip(container.position.y + container.dimensions.height2 - 20, small);
        hole.apply(myBatch);
        //Log.log(container.position.y);
      }

      // Mask
      let round: RoundClip = new RoundClip(small, 4);
      round.apply(myBatch, this.fbo);

      // Shadow
      //this.fbo.ctx.beginPath();
      /*this.fbo.ctx.shadowColor = this.shadowColor; //"rgba(0,0,0,0.8)";
      this.fbo.ctx.shadowBlur = 10;
      myBatch.ctx.shadowOffsetX = this.shadowOffset.x;
      myBatch.ctx.shadowOffsetY = this.shadowOffset.y;

      myBatch.drawImage(this.fbo.handle, 0, 0);

      myBatch.ctx.shadowColor = "transparent";
      myBatch.ctx.shadowBlur = 0;
      myBatch.ctx.shadowOffsetX = 0;
      myBatch.ctx.shadowOffsetY = 0;*/


      for (const container of this.containers) {

        for (const direction of container.getHoles()) {
          let c: Circle;
          const r = 2.5;
          switch (direction) {
            case HoleDirection.Up:
              c = new Circle(new Vector(0, this.contentDimensionsWithPadding.height2), r);
              break;
            case HoleDirection.Down:
              c = new Circle(new Vector(0, -this.contentDimensionsWithPadding.height2), r);
              break;
            case HoleDirection.Left:
              c = new Circle(container.position.sub(new Vector(this.contentDimensionsWithPadding.width2, -container.dimensions.height2 + 20)), r);
              break;
            case HoleDirection.Right:
              c = new Circle(container.position.add(new Vector(this.contentDimensionsWithPadding.width2, container.dimensions.height2 - 20)), r);
              break;
          }

          myBatch.beginPath();
          myBatch.ctx.fillStyle = "gray";
          c.render(myBatch);
          myBatch.fill();
          myBatch.closePath();
        }

      }

      this.compactBlock.recalc();

      this.isDirty = false;
      this.bgRender = false;
    }

    switch (this.getScaleType(this.getScale(batch))) {
      case Scales.Tiny:
        batch.beginPath();
        batch.ctx.fillStyle = (this.isSelected || this.isActive) ? this.shadow.tint : this.tinyColor;
        batch.moveTo(this.position.x, this.position.y);
        batch.arc(this.position.x, this.position.y, this.dotRadius, 0, Math.PI * 2);
        batch.fill();
        batch.closePath();
        break;
      case Scales.Compact:
        this.compactBlock.render(batch);
        break;
      case Scales.Normal:

        if (batch.zoom <= 1) {
          if (this.shadow.isDirty)
            this.shadow.render(this.batch);
          this.shadow.performRender(batch, this.position.x, this.position.y, batch.scale(this.dimensions.width, this.getScale(batch)), batch.scale(this.dimensions.height, this.getScale(batch)));
          batch.drawImage(this.fbo.handle, this.position.x, this.position.y, batch.scale(this.dimensions.width, this.getScale(batch)), batch.scale(this.dimensions.height, this.getScale(batch)));
        }
        else {
          batch.drawImage(this.fbo.handle, this.position.x, this.position.y, this.dimensions.width * batch.zoom, this.dimensions.height * batch.zoom);
        }

        for (const container of this.containers) {
          container.clearHoles();
        }
        break;
    }


  }

  /*
  get innerDimensions(): Dimensions {
    return this.contentDimensions.padding(new Vector(-8, -8));
  }*/

  public calculatedWidth(): number {
    let w = this.minWidth; // todo: calc width
    for (const container of this.containers) {
      if (w < container.blockWidth()) {
        w = container.blockWidth();
      }
    }
    return Math.min(this.maxWidth, Math.max(this.minWidth, w));
  }

  public calculatedHeight(): number {
    let result = 0;


    for (const container of this.containers) {
      result += container.calculatedHeight();
      /*Log.log(container.dimensions);
      Log.log(container.calculatedHeight());
      Log.log(result);*/
    }

    return Math.min(this.maxHeight, Math.max(this.minHeight, result));
  }


  public setAllDirty() {
    super.setAllDirty();
    for (let element of this.containers) {
      element.setAllDirty();
    }
  }

  get contentDimensions(): Dimensions {
    return this._contentDimensions;
  }


  public addNode(node: bNode): Block {

    this.containers.push(node);
    node.initialize(this);

    this.updateDimensions();

    this.updateContainerPositions();
    this.setAllDirty(); // todo: maybe remove

    return this;
  }

  public updateContainerPositions() {
    //Log.log("upd container pos");
    //Log.log(this.contentDimensions);

    let current = this._contentDimensions.height2;//.padding(this.innerPadding.mul(new Vector(-1, -1))).height2;
    for (const container of this.containers) {

      //Log.log("curr: " + current);
      //Log.log(container.calculatedHeight() * .5);
      container.setY(current - container.calculatedHeight() * .5);
      current -= container.calculatedHeight();
    }
  }

  setY(value: number) {
    this.position = new Vector(this.position.x, value);
  }

  applyBlockWidth(): boolean {
    return false;
  }

  public get contentDimensionsWithPadding(): Dimensions {
    return this.contentDimensions.padding(this.innerPadding);
  }


  getScale(batch: Batch): number {
    if (batch.zoom <= 1) {
      return Math.min(1, Math.max(batch.zoom, Math.pow(this.scale / (Field.minScale), this.scale) * batch.zoom));
    }
    else
      return batch.zoom;
  }


  public getFirst(): Blockable {
    if (!this.containers.length)
      return null;
    return this.containers[0];
  }
  public getLast(): Blockable {
    if (!this.containers.length)
      return null;
    return this.containers[this.containers.length - 1];
  }

  clearHoles() {
  }

  getHoles(): Array<HoleDirection> {
    return [];
  }

  worldPosition(batch): Vector {
    return this.position;
  }


  public getContainers(): Array<Blockable> {
      return this.containers;
  }



  public getScaleType(zoom: number): Scales {
      const batch = this.batch;
      batch.zoom = zoom;
      const size = this.getScale(batch);
      if (size <= Scales.Tiny) {
        return Scales.Tiny;
      }
      if (size <= Scales.Compact) {
        return Scales.Compact;
      }
      return Scales.Normal;
  }


  click(event: Event): Event {
    let result = super.click(event);
    console.log("block clicked");
    console.log(result);

    //const ty = event.batch.translateY(this.position.y);
    //console.log(event.batch.translateY(event.original.clientY));
    //console.log(this.worldPosition(event.batch).str());

    const y = result.original.clientY;  // -event.original.clientY + event.batch.dimensions.height2 - event.batch.position.y / event.batch.zoom;
    //console.log(y);

    for (const node of this.containers) {
      //console.log(node.worldPosition(event.batch).str());
      const pos = node.worldPosition(result.batch);

      const ty = result.batch.translateY(pos.y);
      const th = result.batch.scale(node.dimensions.height2, this.getScale(result.batch));

      //console.log(pos.str());
      //console.log(ty);
      //console.log(th);
      if (ty + th >= y && ty - th <= y) {
        node.click(result);
        break;
      }
    }

    /*for (const action of result) {
      action.hierarchy.object = this;
    }*/
    result.actions.setAllObject(this);  //todo: is this really good?
    return result;

  }

  doubleClick(event: Event): Event {
    let result = super.doubleClick(event);
    const y = result.original.clientY;  // -event.original.clientY + event.batch.dimensions.height2 - event.batch.position.y / event.batch.zoom;
    for (const node of this.containers) {
      const pos = node.worldPosition(result.batch);

      const ty = result.batch.translateY(pos.y);
      const th = result.batch.scale(node.dimensions.height2, this.getScale(result.batch));

      if (ty + th >= y && ty - th <= y) {
        node.doubleClick(result);
        break;
      }
    }

    /*for (const action of result) {
      action.hierarchy.object = this;
    }*/
    result.actions.setAllObject(this);  //todo: is this really good?

    return result;
  }

  public select() {
    this.shadow.startTint();
    this.compactBlock.shadow.startTint();
    this.isSelected = true;
  }
  public unselect(): Array<Action> {
    this.shadow.stopTint();
    this.compactBlock.shadow.stopTint();
    this.isSelected = false;
    for (const container of this.containers) {
      container.unselect();
    }
    return [];
  }


  getButtons(): Array<ButtonGroup> {
    let result = super.getButtons();

    for (const container of this.containers) {
      result = result.concat(container.getButtons());
    }

    return result;
  }


  onMove(event: MoveEvent): MoveEvent {
    let result = super.onMove(event);
    if (this.compactBlock) {
      this.compactBlock.position = this.position;
    }

    for (const node of this.containers) {
      node.onBlockMove(result);
    }

    return result;
  }

  onBlockMove(event: MoveEvent): MoveEvent {
    return event;
  }


  hoverStart(cursor?: Vector) {
    super.hoverStart(cursor);
    this.shadow.startTint();
    this.compactBlock.shadow.startTint();
  }

  hoverEnd(cursor?: Vector) {
    super.hoverEnd(cursor);
    if (!this.isSelected) {
      this.shadow.stopTint();
      this.compactBlock.shadow.stopTint();
    }
  }


  createButtons(): Array<ButtonGroup> {
    let result = super.createButtons();

    for (const node of this.containers) {
      result = result.concat(node.createButtons());
    }

    return result;
  }

  typeKey(): string {
    return 'block';
  }

  blockWidth(): number {
    return this.dimensions.width;
  }

  public dragging(event: Event, height: number) {
    console.log("dragging", height);


    const y = event.original.clientY;  // -event.original.clientY + event.batch.dimensions.height2 - event.batch.position.y / event.batch.zoom;
    //console.log(y);
    const newContainers: Array<Blockable> = [];

    for (const node of this.containers) {
      const pos = node.worldPosition(event.batch);

      const ty = event.batch.translateY(pos.y);
      const th = event.batch.scale(node.dimensions.height2, this.getScale(event.batch));
      if (ty + th >= y && ty - th <= y) {
        const dummy = new NodeDummy(new Dimensions(this.contentDimensionsWithPadding.width, height));
        dummy.initialize(this);
        newContainers.push(dummy);
      }

      if (node.typeKey() != 'dummy') {
        newContainers.push(node);
      }
    }

    this.containers = newContainers;

    this.updateContainerPositions();

    this.setAllDirty();
  }



}


export enum Scales {
  Normal = 1,
  Compact = .5,
  Tiny = .33
}
