import {TfGodObject} from './tfGodObject';
import {TfFieldItem} from './tfFieldItem';
import {TfUniqueObject} from './tfUniqueObject';
import {TfNodeContainer} from './tfNodeContainer';
import {TfConnection} from './tfConnection';


export class TfField extends TfGodObject {

  private _title: string;
  private _items: Array<TfFieldItem>;
  private _map: Map<string, TfUniqueObject>;
  private _connections: Array<TfConnection>;


  constructor(id: string, title: string) {
    super(id);
    this._title = title;
    this._items = [];
    this._map = new Map<string, TfUniqueObject>();
    this._map.set(id, this);
    this._connections = [];
  }

  public addItem(item: TfFieldItem): void {
    this._items.push(item);
    this._map.set(item._id, item);
    for (const container of item.containers) {
      this._map.set(container._id, container);
      for (const node of container.nodes) {
        this._map.set(node._id, node);
        if (node.preview) {
          this._map.set(node.preview._id, node.preview);
        }
        if (node.resource) {
          if (node.resource._id)
            this._map.set(node.resource._id, node.resource);
          if (node.resource.preview._id)
            this._map.set(node.resource.preview._id, node.resource.preview);
        }
      }
      for (const connection of container.connections) {
        this._map.set(connection._id, connection);
      }
    }
  }


  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get items(): Array<TfFieldItem> {
    return this._items;
  }

  set items(value: Array<TfFieldItem>) {
    this._items = value;
  }


  get map(): Map<string, TfUniqueObject> {
    return this._map;
  }

  set map(value: Map<string, TfUniqueObject>) {
    this._map = value;
  }


  get connections(): Array<TfConnection> {
    return this._connections;
  }

  set connections(value: Array<TfConnection>) {
    this._connections = value;
  }
}
