


import {RenderObject} from "../eleanor/renderObject";
import {Batch} from "../eleanor/batch";
import {Vector} from "../eleanor/tools/vectors";
import {CustomShape} from "../eleanor/shapes/customShape";
import {Bezier} from "../eleanor/tools/bezier";
import {BezierLine} from "../eleanor/shapes/bezierLine";
import {bNode} from '../node/node';

export class Connection extends RenderObject
{

    public line: BezierLine;
    public aNode: bNode;
    public bNode: bNode;
    public lastXA: number = 0;
    public lastXB: number = 0;

    constructor(a: bNode, b: bNode) {
        super();
        this.aNode = a;
        this.bNode = b;
    }

    render(batch: Batch): void {
        this.line.render(batch);
    }


    public update(delta: number, zoom: number = 1): void {
        super.update(delta);
//console.log("upd: " + zoom);
      let a = this.aNode.holeVectorFor(this.bNode, zoom);
      let b = this.bNode.holeVectorFor(this.aNode, zoom);
      if (a.x != this.lastXA || b.x != this.lastXB) {
        // maybe handle that on block level
        //this.aNode.setDirty();
        //this.bNode.setDirty();
      }

      this.lastXA = a.x;
      this.lastXB = b.x;


      const minMag = 60;
      const mag = a.sub(b).length();
      const bufferRate = mag < minMag ? 0 : .33;
      const m = Math.min((mag - minMag * .5) * bufferRate, 100);
      a = a.mul(new Vector(1, 1, m, m));
      b = b.mul(new Vector(1, 1, m, m));

      this.line = new BezierLine(a, b);

      this.line.update(delta);
    }
}
