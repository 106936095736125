import {RenderObject} from '../eleanor/renderObject';
import {Batch} from '../eleanor/batch';
import {Vector} from '../eleanor/tools/vectors';
import {Text} from '../eleanor/text/text';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Font} from '../eleanor/text/font';


export class CompactRenderer extends RenderObject {

  private _color: string;
  private _bgColor: string;
  private _angle: Vector;
  private _icon: string;
  private _radius: number;

  private _usedAngle: number = 0;

  protected text: Text;


  constructor(color: string, bgColor: string, angle: Vector, icon: string, radius: number) {
    super();
    this._color = color;
    this._bgColor = bgColor;
    this._angle = angle;
    this._icon = icon;
    this._radius = radius;

    this.text = new Text(icon, new Vector(), new Dimensions(radius, radius), new Font(['essence'], 50, 'bolder'), 'center', 'center');
    this.text.color = color;

  }

  render(batch: Batch): void {

    this.text.maxDimensions = new Dimensions(this.radius * 2, this.radius * 2);
    this.text.content = this.icon;
    this.text.color = this.color;
    this.text.setDirty();
    this.text.adjust();

    let start = this.angle.y;
    let end = this.angle.x;

    /*if (start % (Math.PI * 2) == 0 && end % (Math.PI * 2) == 0) {

    }
    else{
      if (start > Math.PI) {
        start -= Math.PI * 2
      }
      if (end > Math.PI) {
        end -= Math.PI * 2;
      }
    }*/

    batch.beginPath();
    batch.moveTo(0, 0);
    batch.arc(0, 0, this._radius, Math.PI * 2 - start, Math.PI * 2 - end);
    batch.ctx.fillStyle = this._bgColor;
    batch.ctx.strokeStyle = 'transparent';
    batch.fill();
    batch.closePath();

    const angle = this._angle.x % (Math.PI * 2) === 0 && this._angle.y % (Math.PI * 2) === 0 ? 0 : (this._angle.x + this._angle.y) * .5;
    const iconOffset = this._radius * .5;
    const fontOffset = this.text.font.size * .25;
    if (angle === 0) {  // only one icon means full circle means icon needs to be centered
      this.text.position = new Vector(0, -fontOffset);
    }
    else {
      this.text.position = new Vector(Math.cos(angle) * iconOffset, Math.sin(angle) * iconOffset - fontOffset);
    }

    this.text.update(0);
    this.text.render(batch);

  }


  get angle(): Vector {
    return this._angle;
  }

  set angle(value: Vector) {
    this._angle = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }


  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get bgColor(): string {
    return this._bgColor;
  }

  set bgColor(value: string) {
    this._bgColor = value;
  }


  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get usedAngle(): number {
    return this._usedAngle;
  }

  set usedAngle(value: number) {
    this._usedAngle = value;
  }
}
