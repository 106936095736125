export const list1 = {
    title: 'Dimensions results for \n  \'Search Result Clustering\'',
    items: [
        {
            type: 'literature',
            author: 'Salman Mahmood, Klaus Mueller',
            title: 'Taxonomizer: Interactive Construction of Fully Labeled Hierarchical Groupings from Attributes of Multivariate Data',
            year: 2019
        }, {
            type: 'literature',
            author: 'Chandramani Chaudhary, Poonam Goyal, Siddhant Tuli, Shuchita Banthia, Navneet Goyal, Yi-Ping Phoebe Chen',
            title: 'A novel multimodal clustering framework for images with diverse associated text', 
            year: 2015
        }, {
            type: 'literature',
            author: 'Nidheesh Melethadathil, Jaap Heringa, Bipin Nair, Shyam Diwakar',
            title: 'Mining Inter-Relationships in Online Scientific Articles and its Visualization: Natural Language Processing for Systems Biology Modeling',
            year: 2018
        }, {
            type: "literature",
            author: 'Alessia Amelio, Andrea Tagarelli', title: 'Data Mining: Clustering', 
            year: 2019
        }, {
            type: 'literature',
            author: 'A. Mouakher, S. Ben Yahia',
            title: 'On the efficient stability computation for the selection of interesting formal concepts',
            year: 2014
        }]
};