import {bNode} from './node';
import {Batch} from '../eleanor/batch';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Block} from './block';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {Log} from '../eleanor/tools/log';
import {BUtils} from '../eleanor/tools/bUtils';


export class ImageNode extends bNode {


  public preview: string;
  public image: any;
  public ratio: number;
  public renderDimensions: Dimensions;


  constructor(preview: string, ratio: number) {
    super();
    this.preview = preview;
    this.ratio = ratio;
    this.renderDimensions = this.dimensions;
    this.compact.icon = '';

  }

  protected initializeImage() {
    this.image = new Image(this.renderDimensions.width, this.renderDimensions.height);
    this.image.src = 'data:image/jpg;base64,' + this.preview;
    const color = BUtils.getAverageRGB(this.image);
    this.compact.bgColor = color.string();
    this.compact.color = (color.value.x + color.value.y + color.value.z) * .33 > .6 ? 'black' : 'white';
    this.block.tinyColor = color.string();
    if (this.todo) {
      this.todo.color = this.compact.color;
      this.todo.setAllDirty();
    }

  }


  initialize(block: Block) {
    super.initialize(block);
    this.applyBlockWidth();
    this.initializeImage();

  }


  applyBlockWidth(): boolean {
    super.applyBlockWidth();


    const old = this.dimensions.height;
    this.dimensions = new Dimensions(this.block.contentDimensions.width, this.block.contentDimensions.width / this.ratio);
    this.renderDimensions = new Dimensions(this.block.contentDimensionsWithPadding.width, this.block.contentDimensionsWithPadding.width / this.ratio);
    Log.log('new dimens ' + this.dimensions.str);
    Log.log('new render dimens ' + this.renderDimensions.str);
    Log.log('block ' + this.block.contentDimensionsWithPadding.str);

    this.dimensions = new Dimensions(this.dimensions.width, this.renderDimensions.height);

    this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.renderDimensions);

    return old != this.dimensions.height;
  }


  calculatedHeight(): number {
    return this.dimensions.height;
  }

  protected renderContent(batch: Batch) {

    if (this.isDirty) {
      this.initializeImage();
      batch.drawImage(this.image, 0, 0, this.renderDimensions.width, this.renderDimensions.height);

    }

  }


  public get batch(): Batch {
    const b = new Batch(this.fbo.ctx, null, this.renderDimensions);
    b.isPixelPerfect = false;
    return b;
  }


  blockWidth(): number {
    return 0;
  }

  typeKey(): string {
    return 'image';
  }
}
