import {Vector} from "./vectors";


export class Dimensions
{
  static infinite = -1;
    private _width: number;
    private _height: number;
    private _width2: number;
    private _height2: number;


    constructor(width: number, height: number) {
        this._width = +width;
        this._height = +height;
        this._width2 = +width * .5;
        this._height2 = +height * .5;
    }

    public padding(padding: Vector): Dimensions
    {
        return new Dimensions(this.width + padding.x, this.height + padding.y);
    }

    public copy(): Dimensions
    {
        return new Dimensions(this.width, this.height);
    }

    get width(): number {
        return this._width;
    }
    get height(): number {
        return this._height;
    }


  get width2(): number {
    return this._width2;
  }

  get height2(): number {
    return this._height2;
  }

  get str(): string {
      return '{w: ' + this.width + ', h: ' + this.height + '}';
  }
}
