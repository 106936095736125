import {ComplexRenderObject} from "../complexRenderObject";
import {Batch} from "../batch";
import {Dimensions} from "../tools/dimensions";
import {Line} from "./line";
import {Vector} from "../tools/vectors";
import {FrameBuffer} from "../frameBuffer";
import {Font} from "./font";
import {Log} from "../tools/log";
import {BMath} from "../tools/bMath";
import {environment} from '../../../../../../environments/environment';


export class Text extends ComplexRenderObject{


    public maxDimensions: Dimensions;
    public minDimensions: Dimensions;
    public actDimensions: Dimensions;
    public renderDimensions: Dimensions;
    public font: Font;
    public lines: Array<Line>;
    public content: string;
    public color: string = "black";
    public autoWidth: boolean;


    public changed: boolean;
    public align: string;
    public valign: string;

    public padding: Vector;


    constructor(content: string, position: Vector, dimensions: Dimensions, font?: Font, align?: string, valign?: string, minDimensions?: Dimensions) {
        super();
        this.content = content;
        this.position = position;
        this.maxDimensions = dimensions;
        if (minDimensions)
            this.minDimensions = minDimensions;
        else
            this.minDimensions = this.maxDimensions.copy();
        this.actDimensions = this.minDimensions.copy();

        this.renderDimensions = this.maxDimensions;

        this.fbo = new FrameBuffer(this);
        this.fbo.setDimensions(this.maxDimensions);

        this.font = font ? font : new Font();
        this.align = align ? align : "";
        this.valign = valign ? valign : "";

        this.changed = true;
        this.isDirty = true;

        this.padding = new Vector(0, 0);

        //Log.info(this.maxDimensions);
    }

    private del = 0;

    update(delta: number): void {
        super.update(delta);
        /*this.del += delta;
        let t = -500;
        if (this.del > t)
        {
            this.del -= t;
            this.changed = true;
            this.isDirty = true;
            this.content = this.content + " eyoo";
        }*/

        if (this.changed)
        {


            //Log.log("update");
            this.lines = [];
          let widest = 0;
          Log.log("upd text del: " + delta);
          Log.log("calced: " + this.calculatedHeight + ", maxdims: " + this.renderDimensions.str);

            for (const naturalLine of this.content.split('\n'))
            {
              let buffer = "";

              let words = naturalLine.split(" ");

              let batch = this.batch;
              let height = this.font.height();
              let lineWidth = 0;
              let lastLineWidth = 0;


              batch.ctx.font = this.font.getString();
              let spaceWidth = batch.textWidth(" ");
              let isFirstWord = true;
              let lastWord: string = '';
              let lastLine: Line = null;
              for (let word of words)
              {
                let wordWidth = batch.textWidth(word);
                lineWidth += wordWidth;

                //Log.log(word + ": " + wordWidth);
                //Log.log(lineWidth + ": " + buffer);
                if (lineWidth > this.maxDimensions.width - 8)
                {
                  //Log.log(lineWidth + " >" + (this.maxDimensions.width - 5));
                  //Log.log(buffer);
                  lineWidth -= wordWidth;
                  lastLineWidth = lineWidth;
                  if (lastLineWidth > widest)
                    widest = lastLineWidth;
                  this.lines.push(new Line(buffer, new Dimensions(lineWidth, height * this.font.lineHeightSpacing)));
                  if (this.calculatedHeight > this.maxDimensions.height - height)
                  {
                    Log.log("max height exceeded. calced: " + this.calculatedHeight + ", maxdims: " + this.maxDimensions.str + ", curr: " + height);
                    let line = this.lines[this.lines.length - 1];
                    line.content = line.content.substring(0, line.content.length - 4);
                    line.content = line.content + "...";
                    buffer = "";
                    break;
                  }
                  buffer = word + " ";
                  lineWidth = wordWidth + spaceWidth;
                  isFirstWord = true;
                }
                else
                {
                  buffer += word + " ";
                  lineWidth += spaceWidth;
                  isFirstWord = false;
                }

              }

              if (buffer != "")
              {
                //lineWidth -= spaceWidth; //experimental
                if (lineWidth > widest)
                  widest = lineWidth;
                //Log.log("end line: "+lineWidth);
                this.lines.push(new Line(buffer, new Dimensions(lineWidth, height * this.font.lineHeightSpacing)));
                if (this.content == 'How to use thinkfield') {
                  Log.log("!! line dim: " + lineWidth);
                }
              }

              for (const line of this.lines) {
                line.dimensions = new Dimensions(line.dimensions.width - spaceWidth, line.dimensions.height);
                if (this.content == 'How to use thinkfield') {
                  Log.log("!! line dim: " + line.dimensions.width);
                }
              }



            }

          //Log.log("widest: " + widest);
          this.actDimensions = new Dimensions(
            Math.min(Math.max(widest, this.minDimensions.width), this.maxDimensions.width),
            Math.max(Math.min(this.calculatedHeight, this.maxDimensions.height), this.minDimensions.height)
          );

          this.renderDimensions = this.actDimensions.copy();
            Log.log("finished upd: act; " + this.actDimensions.str + ", max: " + this.maxDimensions.str);

          //Log.log(this.lines.length);
          //Log.log(this.actDimensions);
          this.changed = false;
          //Log.log(this.lines);
        }

    }

    render(batch: Batch): void
    {
        if (this.isDirty)
        {
            let myBatch = this.batch;
            myBatch.clearAll();

            if (environment.debug) {

              myBatch.beginPath();
              myBatch.ctx.fillStyle = "rgba(255,0,0,.5)";
              //myBatch.rect(0, 0, this.dimensions.width, this.dimensions.height);
              myBatch.fill();
              myBatch.closePath();
              myBatch.ctx.lineWidth = 1;
              myBatch.ctx.strokeStyle = "green";
              myBatch.rect(0, 0, this.minDimensions.width - 2, this.minDimensions.height - 2);
              myBatch.stroke();
              myBatch.closePath();
              myBatch.beginPath();
              myBatch.ctx.strokeStyle = "red";
              myBatch.rect(0, 0, this.maxDimensions.width - 2, this.maxDimensions.height - 2);
              myBatch.stroke();
              myBatch.closePath();
              myBatch.beginPath();
              myBatch.ctx.strokeStyle = "blue";
              myBatch.rect(0, this.yOffset(), this.actDimensions.width - 2, this.actDimensions.height - 2);
              myBatch.stroke();
              myBatch.closePath();

            }

            Log.log("rendering " + this.content);
            Log.log("min: " + this.minDimensions.str + ", act: " + this.actDimensions.str+ ", max: " + this.maxDimensions.str+ ", ren: " + this.renderDimensions.str);

            myBatch.ctx.font = this.font.getString();
            //myBatch.ctx.font = "16px Arial";

            let buffer: number = this.calculatedHeight * .5;
          myBatch.ctx.fillStyle = this.color;
            for (let line of this.lines)
            {
                buffer -= line.height2;
                myBatch.fillText(line.content, this.xPosByLine(line), buffer + this.yOffset());
                if (environment.debug) {
                  myBatch.beginPath();
                  myBatch.moveTo(this.xPosByLine(line) + 5, buffer + this.yOffset());
                  myBatch.lineTo(this.xPosByLine(line) + line.width, buffer + this.yOffset());
                  myBatch.stroke();
                  myBatch.closePath();
                }
                buffer -= line.height2;
            }

            this.isDirty = false;
        }


        //Log.log(this.position);
        //Log.log(this.maxDimensions);
        batch.drawImage(this.fbo.handle, this.position.x, this.position.y);//), todo: this is dive zoom: batch.scale(this.maxDimensions.width, this.getScale(batch)), batch.scale(this.maxDimensions.height, this.getScale(batch)));

    }


    public get calculatedHeight(): number
    {
        let result = 0;
        for (let line of this.lines)
        {
            result += line.height;
        }
        return result;
    }

    public xPosByLine(line: Line): number
    {
        switch (this.align) {
            case "center":
                return -line.width2;
            case "right":
                return this.actDimensions.width * .5 - line.width;
            default:
                return -this.actDimensions.width * .5;
        }
    }
    public yOffset(): number
    {
        switch (this.valign) {
          case "bottom":
              return this.renderDimensions.height * -.5 + this.calculatedHeight * .5;
          case "after":
              return this.renderDimensions.height * -.5 + this.calculatedHeight * .2;
            case "center":
                return 0;
            default:
                return this.renderDimensions.height * .5 - this.calculatedHeight * .5;
        }
    }

    get dimensions()
    {
      return this.renderDimensions.padding(this.padding);
    }


  set dimensions(value: Dimensions) {
    this.renderDimensions = value;
  }

  public adjust(): void
    {
      Log.log('adjust ' + this.content);
      this.changed = true;
      this.update(0);
      //this.actDimensions = this.minDimensions.copy();
      //this.renderDimensions = this.minDimensions.copy();
      //Log.log(this.maxDimensions);
      //this.maxDimensions = new Dimensions(this.actDimensions.copy().width, this.maxDimensions.height);
      //this.renderDimensions = this.actDimensions.copy();
      //Log.log(this.maxDimensions);

      // don't know why i did dis
      //this.minDimensions = new Dimensions(
      //  Math.min(this.minDimensions.width, this.renderDimensions.width),
      //  Math.min(this.minDimensions.height, this.renderDimensions.height),
      //);
      this.changed = true;
      this.isDirty = true;

      if (this.autoWidth) {
        for (const line of this.lines) {
          line.dimensions = new Dimensions(this.renderDimensions.width, line.dimensions.height);
        }
      }

      this.fbo = new FrameBuffer(this);
      this.fbo.setDimensions(this.renderDimensions);
    }


}
