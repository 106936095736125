import {Renderable} from "./interfaces/renderable";
import {Idable} from "./interfaces/idable";
import {Batch} from "./batch";
import {Canvas} from "./canvas";
import {Bounds} from "./tools/bounds";
import {Vector, Vector3} from "./tools/vectors";
import {Dimensions} from "./tools/dimensions";
import {Layer} from "./layer";
import {HitBox} from "./tools/hitBox";
import {Log} from "./tools/log";
import {BMath} from './tools/bMath';
import {Text} from './text/text';
import {Event} from './tools/event';
import {Action} from './action/action';
import {EmptyAction} from './action/emptyAction';
import {ButtonGroup} from '../ui/buttonGroup';
import {Actions} from "./action/actions";
import {MoveEvent} from "./tools/moveEvent";


export abstract class RenderObject implements Renderable, Idable
{

    uuid: number;
    isDirty: boolean;
    protected canvas: Canvas;
    protected layer: Layer;
    private _dimensions: Dimensions;
    private _hitBox: Dimensions;
    public position: Vector;
    public isSelected: boolean = false;
    protected _toRemove: boolean = false;
    public isHidden: boolean = false;

  public scale: number;


  constructor() {
    this.scale = 1;
  }

    abstract render(batch: Batch): void;

    public update(delta: number, zoom: number = 1): void
    {
    }


  public getScale(batch: Batch): number
  {
    return Math.min(1, Math.max(batch.zoom, this.scale * batch.zoom));
  }



    get width(): number
    {
        return this.dimensions.width;
    }
    get height(): number
    {
        return this.dimensions.height;
    }
    get width2(): number
    {
        return this.dimensions.width2;
    }
    get height2(): number
    {
        return this.dimensions.height2;
    }

  setAllDirty() {

  }

  get dimensions() {
      return this._dimensions;
  }


  set dimensions(value: Dimensions) {
    this._dimensions = value;
  }

  public isVisible(batch: Batch)
  {
    if (this.isHidden) {
      return false;
    }
    const tx = batch.translateX(this.position.x);
    const ty = batch.translateY(this.position.y);
    const tw = batch.scale(this.dimensions.width, this.getScale(batch));
    const th = batch.scale(this.dimensions.height, this.getScale(batch));
    const exp = tx + tw >= 0
             && tx - tw <= batch.dimensions.width
             && ty + th >= 0
             && ty - th <= batch.dimensions.height;

    /*if ((<any>window).x != this)
    {
      (<Text>(<any>window).x).content = "  x: " + BMath.round(batch.position.x * batch.zoom, 2) + "  y: " + BMath.round(batch.position.y * batch.zoom, 2) +
                                      "\n tx: " + tx +
                                      "\n tw: " + tw +
                                      "\n  v: " + exp;
      (<Text>(<any>window).x).changed = true;
      (<Text>(<any>window).x).setAllDirty();
    }*/
    return exp;
  }

  public isHit(batch: Batch, position: Vector): boolean {

      if (this.isHidden || !position || !this.position) {
        return false;
      }
      const dim = this._hitBox || this.dimensions;

    const tx = batch.translateX(this.position.x);
    const ty = batch.translateY(this.position.y);
    const tw = batch.scale(dim.width2, this.getScale(batch));
    const th = batch.scale(dim.height2, this.getScale(batch));

    const exp = tx + tw >= position.x
      && tx - tw <= position.x
      && ty + th >= position.y
      && ty - th <= position.y;

    /*if ((<any>window).x != this)
    {
      (<Text>(<any>window).x).content = "  x: " + BMath.round(batch.position.x * batch.zoom, 2) + "  y: " + BMath.round(batch.position.y * batch.zoom, 2) +
        "\n tx: " + tx +
        "\n ty: " + ty +
        "\n tw: " + tw +
        "\n th: " + th +
        "\n cx: " + position.x +
        "\n cy: " + position.y +
        "\n  h: " + exp;
      (<Text>(<any>window).x).changed = true;
      (<Text>(<any>window).x).setAllDirty();
    }*/

    return exp;
  }

  get hitBox(): Dimensions {
    return this._hitBox;
  }

  set hitBox(value: Dimensions) {
    this._hitBox = value;
  }

  public click(event: Event): Event {
    return event;
  }

  public mouseUp(event: Event): Event {
    return event;
  }

  public doubleClick(event: Event): Event {
    return event;
  }

  public hoverStart(cursor?: Vector) {

  }

  public hoverEnd(cursor?: Vector) {

  }
  public hoverMove(cursor?: Vector) {

  }

  public unselect(): Array<Action> {
      console.log("unselect a");
      return [];
  }
  public select() {

  }


  public createButtons(): Array<ButtonGroup> {
    return [];
  }

  public getButtons(): Array<ButtonGroup> {
    return [];
  }


  get toRemove(): boolean {
    return this._toRemove;
  }

  public remove() {
      this.onRemove();
      this._toRemove = true;
  }
  //set toRemove(value: boolean) {
  //  if (value) {
  //    this.onRemove();
  //  }
  //  this._toRemove = value;
  //}

  public onRemove() {

  }

  public onMove(event: MoveEvent): MoveEvent {
    return event;
  }
}
