import {Layer} from "../eleanor/layer";
import {Batch} from "../eleanor/batch";
import {Canvas} from "../eleanor/canvas";
import {Connection} from "./connection";
import {Vector} from "../eleanor/tools/vectors";
import {Log} from "../eleanor/tools/log";
import {ObjectLayer} from "../eleanor/objectLayer";


export class ConnectionLayer extends ObjectLayer
{


    constructor(canvas: Canvas, poolSize: number) {
        super(canvas, poolSize);

        /*for (let i=0; i<10; i++)
        {

            this.addObject(new Connection(
                    new Vector(Math.random() * 10000, Math.random() * 10000),
                    new Vector(Math.random() * 10000, Math.random() * 10000),
                ))


        }*/

    }

    render(batch: Batch) {

        if (this.isDirty)
        {
            //Log.log("fbo render");
            this.clear();
            let ctx = this.fbo.ctx;
            let b = this.getBatch();
            ctx.fillStyle = "transparent";
            ctx.strokeStyle = "gray";
            ctx.beginPath();

            for (let connection of this.pool)
            {
                connection.render(b);
            }

            ctx.stroke();
            ctx.closePath();
            this.isDirty = false;
        }
    }


}
