
import {Canvas} from "./canvas";
import * as $ from "./lib/jquery-3.3.1.min.js";
import {Vector, Vector2} from "./tools/vectors";
import {BMath} from "./tools/bMath";
import {Bezier} from "./tools/bezier";
import {Uuid} from "./tools/uuid";
import {Accessor} from "./tools/accessor";
import "./lib/hammer.min";
import {CanvasConfig} from "./canvasConfig";
import {Log} from "./tools/log";

//let c: Canvas = new Canvas();
//console.log(c);
console.log("helo");


(<any>window).bp2dInitCanvas = function (element: any, conf: CanvasConfig): Canvas
{
    if (!(<any>window).bp2d)
    {
        console.error("Tried to call window.bp2dInitCanvas(...) but BP2D has not been initialized yet. Call window.bp2dInit(conf) first!");
        return null;
    }

    Log.log("bp init canvas called");

    let canvas: Canvas = new Canvas(element, conf);
    Accessor.instance().add(canvas);

    element.setAttribute("bp2d-id", canvas.uuid);

    return canvas;
};


(<any>window).bp2dInit = function (conf)
{


    let poolSize = conf.poolSize;

    (<any>window).bp2d = {
        "accessor": new Accessor(poolSize),
        "debug": conf.debug,
    };

    //Log.debug = conf.debug;


    Log.log("bp init called");



    return;


    for (let i=0; i<=1.04; i+=.05)
    {
        //c(i);
    }

    let b = new Bezier([
        new Vector2(0, 0),
        new Vector2(1, .5),
        new Vector2(0.5, 1),
        new Vector2(-0.5, .5),
        new Vector2(.5, -.2),
        new Vector2(1.3, 1),
        new Vector2(1.5, .8),
        new Vector2(1, .5),
        //new Vector2(1, 1),
        //new Vector(0, 0),
        //new Vector(0.2, -.3),
        //new Vector(.5, 1.5),
        //new Vector(1, 1),
    ]);

    b = new Bezier([
        new Vector2(0, 0),
        new Vector2(0.3, 0),
        new Vector2(0.6, .5),
        new Vector2(1, 1),
    ]);
    b.calcPoints(5);
    let pp = b.getCachedPointsArray();

    for (let v of pp)
    {
        $("body").append("<div style='width: " + (5) + "px; height: " + (5) + "px; transform: translate(" + (v.x*200) + "px, " + (-v.y*200) + "px); position: absolute; background-color: black; border-radius: 100%;'></div>")

    }
};


