import {Content} from './content';
import {TitleNode} from '../../view/node/titleNode';
import * as $ from 'jquery';


export class Title extends Content {


  content(): string {
    return '<textarea id="value" style="min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">' +
      this.model.stringValue +
      '</textarea>';
  }

  save(): boolean {
    console.log('saving');
    const value = $('#value').val();
    console.log( document.getElementById('value'));
    this.model.stringValue = value;
    (<TitleNode> this.view).setContent(this.model.stringValue);
    this.view.setAllDirty();
    return true;
  }


  contentReadOnly(): string {
    return this.model.stringValue;
  }
}
