import {Batch} from "../batch";
import {Log} from "./log";
import {FrameBuffer} from "../frameBuffer";


export abstract class Mask
{

    public apply(batch: Batch, fbo: FrameBuffer): void
    {

        let copy: FrameBuffer = new FrameBuffer(fbo.owner);
        copy.handle.setAttribute("width", batch.dimensions.width);
        copy.handle.setAttribute("height", batch.dimensions.height);
        let copyBatch: Batch = new Batch(copy.ctx, null, batch.dimensions);

        copyBatch.drawImage(fbo.handle, 0, 0);

        batch.clearAll();

        batch.ctx.save();
        batch.beginPath();

        this.mask(batch);

        batch.closePath();
        batch.clip();


        batch.drawImage(copy.handle, 0, 0);

        batch.restore();
    }


    protected abstract mask(batch: Batch): void;


}