import {Animator} from './animator';
import {Bezier} from './bezier';


export class ValueAnimator extends Animator {

  public startValue: number;
  public targetValue: number;


  constructor(duration: number, bezier: Bezier, isLoop: boolean, isReverse: boolean, startDelay: number, endDelay: number, direction: number, startValue: number, targetValue: number) {
    super(duration, bezier, isLoop, isReverse, startDelay, endDelay, direction);
    this.startValue = startValue;
    this.targetValue = targetValue;
    this.isFinished = true;
  }

  public new(start: number, target: number, duration: number, now?: boolean) {
    this.startValue = start;
    this.targetValue = target;
    this.duration = duration;
    this.timeCount = 0;
    if (!!now) {
      this.isFinished = false;
      this.forward();
    }
  }

  getValue(): number {
    return this.startValue + (this.targetValue - this.startValue) * super.getValue();
  }
}
