import {TfGodObject} from './tfGodObject';
import {TfNode} from './tfNode';


export class TfConnection extends TfGodObject {
  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  get aNode(): TfNode {
    return this._aNode;
  }

  set aNode(value: TfNode) {
    this._aNode = value;
  }

  get bNode(): TfNode {
    return this._bNode;
  }

  set bNode(value: TfNode) {
    this._bNode = value;
  }

  private _label: string;
  private _aNode: TfNode;
  private _bNode: TfNode;


  constructor(id: string, label: string, aNode: TfNode, bNode: TfNode) {
    super(id);
    this._label = label;
    this._aNode = aNode;
    this._bNode = bNode;
  }


}
