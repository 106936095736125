



export class TfUniqueObject {

  private __id: string;


  constructor(uuid: string) {
    this.__id = uuid;
  }

  get _id(): string {
    return this.__id;
  }

  set _id(value: string) {
    this.__id = value;
  }
}
