import {Uuid} from '../tools/uuid';
import {TfNodeType} from '../../../model/tfNode';
import {RenderObject} from '../renderObject';


export class ModalConfig {
  public viewItem: RenderObject;
  public type: string;


  constructor(viewItem: RenderObject, type: string) {
    this.viewItem = viewItem;
    this.type = type;
  }
}
