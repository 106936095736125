import {AddObjectAction} from './addObjectAction';
import {Layer} from '../layer';
import {ActionHandler} from "../interfaces/actionHandler";


export class AddToUiAction extends AddObjectAction {

  targetLayer(): Layer {
    return this.hierarchy.canvas.layerByName('ui');
  }


  perform(actionHandler: ActionHandler) {
    super.perform(actionHandler);
    console.log('gg');
  }
}
