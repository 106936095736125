import {Action} from './action';
import {ActionHandler} from '../interfaces/actionHandler';
import {RenderObject} from '../renderObject';
import {ComplexRenderObject} from '../complexRenderObject';
import {Layer} from '../layer';


export class AddObjectAction extends Action {

  public objects: Array<RenderObject | ComplexRenderObject>;
  public allow;


  constructor(objects: Array<RenderObject | ComplexRenderObject>, allow: boolean = true) {
    super();
    this.objects = objects;
    this.allow = allow;
    console.log('prepare objects');
    console.log(objects);
  }

  perform(actionHandler: ActionHandler) {
    console.log('adding objects');
    console.log(this.objects);
    for (const object of this.objects) {
      this.targetLayer().addObject(object);
    }

  }

  targetLayer(): Layer {
    return this.hierarchy.layer;
  }


  shouldPerform(): boolean {
    return super.shouldPerform() && this.allow;
  }
}
