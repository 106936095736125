import {ComplexRenderObject} from '../eleanor/complexRenderObject';
import {Batch} from '../eleanor/batch';
import {Text} from '../eleanor/text/text';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {Font} from '../eleanor/text/font';
import {FrameBuffer} from '../eleanor/frameBuffer';
import {bNode} from './node';


export class Todo extends ComplexRenderObject {

  public value: boolean;
  public text: Text;
  public node: bNode;
  private _color: string;


  constructor(value: boolean, node: bNode, color: string = 'black') {
    super();
    const size = 32;
    const size2 = 40;
    this.node = node;
    this.value = value;
    this._color = color;
    this.text = new Text(value ? '' : '◯', new Vector(0, 0), new Dimensions(size, size2), new Font(["essence-bold", 'sans-serif'], 22), "center", "center");
    this.position = new Vector(0);
    this.dimensions = new Dimensions(size, size2);
    this.fbo = new FrameBuffer(this);
    this.fbo.setDimensions(this.dimensions);
    this.isDirty = true;
    console.log('new todo');

  }

  render(batch: Batch): void {
    const myBatch = this.batch;

    if (this.isDirty) {
      //console.log('render todo', this);
      myBatch.clearAll();
      this.isDirty = false;
      myBatch.beginPath();
      myBatch.ctx.strokeStyle = this._color;
      myBatch.ctx.lineWidth = 1.5;
      myBatch.arc(0, 0, 12, 0, 2 * Math.PI);
      //myBatch.stroke();
      myBatch.closePath();
      this.text.adjust();
      this.text.render(myBatch);
      //console.log('render todo', this.value, this);

      if (this.value) {
        //myBatch.beginPath();
        //myBatch.ctx.fillStyle = this.color;
        //myBatch.arc(0, 0, 8, 0, 2 * Math.PI);
        //myBatch.fill();
        //myBatch.closePath();
      }
    }

    batch.drawImage(this.fbo.handle, this.width2 - this.node.width2, this.node.height2 - this.height2);
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);
    this.text.update(delta);
  }

  get color(): string {
    return this._color;
  }


  setAllDirty(): void {
    super.setAllDirty();
    this.text.setDirty();
  }

  set color(value: string) {
    this._color = value;
    this.text.color = value;
    this.text.setAllDirty();
  }
}

