import {Idable} from "../interfaces/idable";


export class Accessor {

    protected content: Array<Idable>;

    private id: number;
    private poolSize: number;
    private initialPoolSize: number;

    private static accessor: Accessor;
    public static instance(): Accessor
    {
        return this.accessor;
    }

    constructor(poolSize: number) {
        this.content = new Array<Idable>(poolSize);
        Accessor.accessor = this;
        this.id = 0;
        this.initialPoolSize = poolSize;
        this.poolSize = poolSize;
    }

    private next(): number
    {
        return this.id++;
    }

    public add(obj: Idable): number
    {
        let id = this.next();
        this.content[id] = obj;
        obj.uuid = id;
        return id;
    }

}