import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs/index';
import {NavigationCoordinates} from '../view/NavigationCoordinates';
import {list1} from '../view/demoLists/list1';
import {list2} from '../view/demoLists/list2';
import {list3} from '../view/demoLists/list3';
import {list4} from '../view/demoLists/list4';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @Input() public navigationEvents: Observable<NavigationCoordinates>;

    public list: any = list1;


    public lists = [list1, list2, list3, list4];
    private lastListIndex = -1;
    public todoStates = [];

    public changeList(n: number) {
        if (this.lastListIndex == n) {
            return;
        } // {x}
        let el = document.getElementsByClassName('content')[0];
        let sidebar = document.getElementsByClassName('sidebar')[0];
        if (n == -1) {
            sidebar.classList.add('fade');
            sidebar.classList.remove('reveal');
        }
        else if (this.lastListIndex == -1) {
            sidebar.classList.remove('fade');
            sidebar.classList.add('reveal');
        }
        el.classList.add('fade');
        el.classList.remove('reveal');
        setTimeout(() => {
            this.lastListIndex = n;
            if (n == -1) {
                this.list == null;
                return;
            }
            this.list = this.lists[n];
        }, 300);
        setTimeout(() => {
            if (n != -1) {
                el.classList.add('reveal');
                el.classList.remove('fade');
            }
        }, 600);
    }

    constructor(private elRef: ElementRef) {

    }

    ngOnInit(): void {
        this.navigationEvents.subscribe((event) => {
            console.log('sidebar received nav event: ', event);
            if (event.scale < 0.5) {
                this.changeList(-1);
            }
            else if (event.y < -200) {
                this.changeList(0);
            }
            else if (event.x > 0 && event.y < 200) {
                this.changeList(3);
            }
            else if (event.y <= 400 && event.y > 200 && event.x > 200) {
                this.changeList(1);
            }
            else if (event.y > 400 && event.x > 200) {
                this.changeList(2);
            }
            else {
                this.changeList(-1);
            }

        });
    }


}
