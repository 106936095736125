

export class CanvasConfig
{


    public useContinuousRender: boolean;
    public useAA: boolean;
    public forceRedraw: boolean;
    public usePixelPerfectPositions: boolean;
    public isFullscreen: boolean;
    public width: number;
    public height: number;
    public poolSize: number;


    constructor(useContinuousRender: boolean, useAA: boolean, forceRedraw: boolean,
                usePixelPerfectPositions: boolean, isFullscreen: boolean, width: number, height: number,
                poolSize: number) {
        this.useContinuousRender = useContinuousRender;
        this.useAA = useAA;
        this.forceRedraw = forceRedraw;
        this.usePixelPerfectPositions = usePixelPerfectPositions;
        this.isFullscreen = isFullscreen;
        this.width = width;
        this.height = height;
        this.poolSize = poolSize;

    }
}