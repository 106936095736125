import {UiLayer} from '../eleanor/uiLayer';
import {Canvas} from '../eleanor/canvas';
import {Vector} from '../eleanor/tools/vectors';


export class StaticUiLayer extends UiLayer {

  constructor(canvas: Canvas, poolSize: number) {
    super(canvas, poolSize);
    this.name = 'static_ui';
    //this.modZoom = new Vector();
    this.fixedZoom = 1;
    this.modPosition = new Vector();
  }


  updateSize(): void {
    const x = this.canvas.width / this.fbo.handle.getAttribute('width');
    const y = this.canvas.height / this.fbo.handle.getAttribute('height');
    const v = new Vector(x, y);
    console.log(v.str());

    for (const element of this.pool) {
      element.position = element.position.mul(v);
    }

    super.updateSize();
  }
}
