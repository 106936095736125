

import {Batch} from "./batch";
import {Renderable} from "./interfaces/renderable";
import {Idable} from "./interfaces/idable";
import {RenderObject} from "./renderObject";
import {Canvas} from "./canvas";
import {Camera} from "./tools/camera";
import {Vector} from "./tools/vectors";
import {FrameBuffer} from "./frameBuffer";
import {FrameBufferHolder} from "./interfaces/frameBufferHolder";
import {Pool} from "./tools/pool";
import {Log} from "./tools/log";
import {Event} from './tools/event';
import {Action} from './action/action';
import {EmptyAction} from './action/emptyAction';
import {Updateable} from './interfaces/updateable';
import {Actions} from "./action/actions";

export abstract class Layer implements Idable, Renderable, FrameBufferHolder
{

    public uuid: number;
    public pool: Pool<RenderObject>;
    public additionalUpdateObjects: Pool<Updateable>;
    public initialPoolSize: number;
    public poolSize: number;
    public canvas: Canvas;
    public position: Vector;
    public camera: Camera;
    public fbo: FrameBuffer;
    public isDirty: boolean;
    public modPosition: Vector;
    public modZoom: Vector;
    public fixedZoom: number;
    public hitOrMiss: boolean;
    public selected: Array<RenderObject>;
    public name: string;
    public toRemove: Array<number>;
    public alpha: number;
    public anchor: Vector;
    public hasDragged: boolean;

    public constructor(canvas: Canvas, poolSize: number) {
        this.pool = new Pool(poolSize);
        this.additionalUpdateObjects = new Pool(100);  // todo: well...
        this.poolSize = poolSize;
        this.initialPoolSize = poolSize;
        this.position = new Vector(0, 0);
        this.canvas = canvas;
        this.fbo = new FrameBuffer(this);
        this.camera = new Camera();
        this.isDirty = true;

        this.hitOrMiss = true;

        this.modPosition = new Vector(1, 1, 1, 1);
        this.modZoom = new Vector(1, 1, 1, 1);

        this.selected = [];
        this.toRemove = [];

        this.hasDragged = false;

        this.alpha = 1;

        this.updateSize();
    }

    public update(delta: number, zoom: number = 1)
    {
      for (const id of this.toRemove) {
        this.removeObject(id);
      }
      this.toRemove = [];

      for (let i=0; i < this.pool.length; i++)
      {
        const element = this.pool.get(i);
        if (element) {
          element.update(delta, zoom);
          if (element.toRemove) {
            this.toRemove.push(i);
          }
        }
      }
    }

    public updateSize()
    {
        this.fbo.handle.setAttribute("width", this.canvas.dimensions.width);
        this.fbo.handle.setAttribute("height", this.canvas.dimensions.height);
    }

    public clear(): void
    {
        this.fbo.ctx.clearRect(0, 0, this.canvas.getDimensions().width, this.canvas.getDimensions().height);
    }

    public abstract render(batch: Batch);

    public getBatch(): Batch
    {
        return new Batch(this.fbo.ctx, this.canvas, this.canvas.dimensions, this.canvas.position.mul(this.modPosition), this.modifiedZoom(this.canvas.zoom));
    }

    public modifiedZoom(zoom: number): number
    {
      if (this.fixedZoom)
      {
        return this.fixedZoom;
      }
      return zoom;
    }


    public setAllDirty()
    {
      this.isDirty = true;
      for (let element of this.pool)
      {
        element.setAllDirty();
      }
    }


  public addObject(element: RenderObject): void
  {
    this.pool.add(element);
    this.canvas.registerObject(element);
  }
  public removeObject(index: number): void
  {
    this.canvas.unregisterObject(this.pool.get(index).uuid);
    this.pool.remove(index);
  }

    get width(): number
    {
        return this.canvas.width;
    }
    get height(): number
    {
        return this.canvas.height;
    }

    public isContentHit(): boolean {
      return false;
    }

    public preferredHoverCursor(): string {
      return 'auto';
    }
    public preferredClickCursor(): string {
      return 'auto';
    }

    public click(event: Event): Event {
      event.batch = this.getBatch();
      return event;
    }
    public mouseUp(event: Event): Event {
      event.batch = this.getBatch();
      return event;
    }
    public doubleClick(event: Event): Event {
      event.batch = this.getBatch();
      return event;
    }

    public getIndexByUuid(uuid: number): number {

      for (let i=0; i<this.pool.length; i++)
      {
        if (this.pool.get(i) && this.pool.get(i).uuid == uuid) {
          return i;
        }
      }
      return -1;
    }

    public blockCanvasMove() {
      return false;
    }

    public startMove(event: Event): Event{
      this.anchor = event.center;
      return event;
    }

    public endMove(event: Event): Event{
      this.anchor = null;
      return event;
    }

    public onMove(event: Event): Event{
      return event;
    }


  public fadeIn() {

  }
  public fadeOut() {
  }

}
