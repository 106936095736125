

import {CanvasConfig} from "./canvasConfig";
import {Renderer} from "./renderer";
import {Renderable} from "./interfaces/renderable";
import {Batch} from "./batch";
import {Idable} from "./interfaces/idable";
import {RenderObject} from "./renderObject";
import {Layer} from "./layer";
import {Log} from "./tools/log";
import {FrameBuffer} from "./frameBuffer";
import {Dimensions} from "./tools/dimensions";
import {Bounds} from "./tools/bounds";
import {FrameBufferHolder} from "./interfaces/frameBufferHolder";
import {Pool} from "./tools/pool";
import {Vector} from "./tools/vectors";
import {BUtils} from './tools/bUtils';
import {Event} from './tools/event';
import {Action} from './action/action';
import {EmptyAction} from './action/emptyAction';
import {ValueAnimator} from './tools/valueAnimator';
import {EaseOutBezier} from './tools/bezier';
import {Actions} from "./action/actions";
import {environment} from "../../../../../environments/environment";

export class Canvas implements Idable, FrameBufferHolder
{
    public config: CanvasConfig; // Configures this Canvas object, will hold all config standards for sub layers
    public canvasElement: any; // HTML Canvas Element
    public uuid: number; // uuid
    public isActive: boolean = false;
    public isBackgroundRenderActive: boolean = false;
    public layers: Array<Layer>;
    public pool: Pool<RenderObject>;
    public ctx: any;
    public dimensions: Dimensions;
    public isDirty: boolean;
    private _zoom: number;
    private minZoom = 0.1;
    private maxZoom = 1;

    public zoomAnimator: ValueAnimator;

    public cursor: Vector;

    public timeScale: number = 1;

    public dummyCanvas: any;
    public backgroundRenderList: Array<number> = [];


    public position: Vector;

    public lastFrameTimestamp: number;

    public hitLayer: Layer;

    constructor(canvasElement: any, config: CanvasConfig)
    {
        this.canvasElement = canvasElement;
        this.config = config;
        this.layers = [];
        this.lastFrameTimestamp = performance.now();
        this.pool = new Pool(config.poolSize);
        this._zoom = 1;

        this.cursor = new Vector(-Infinity, -Infinity);

        this.dimensions = new Dimensions(config.width, config.height);

        this.zoomAnimator = new ValueAnimator(300, EaseOutBezier.instance(), false, false, 0, 0, 0, this._zoom, this._zoom);

        this.ctx = canvasElement.getContext("2d");

        canvasElement.setAttribute("width", config.width);
        canvasElement.setAttribute("height", config.height);

        if (config.isFullscreen)
        {
            window.onresize = () => this.updateSize();
            //Log.info(window.screen, this);
            canvasElement.setAttribute("style", (canvasElement.getAttribute("style") || "") +
                ";" +
                "position: fixed;" +
                "overflow: hidden;" +
                "top: 0;" +
                "left: 0");
            this.updateSize();
        }
        if (config.useContinuousRender)
        {
            let tp = this;
            requestAnimationFrame(function ()
            {
                tp.autoRun();
            });
        }

        this.ctx.imageSmoothingEnabled = config.useAA;
        //this.position = new Vector(this.dimensions.width * -.5, this.dimensions.height * -.5, 1);
        this.position = new Vector();

        this.dummyCanvas = document.createElement('canvas');




        this.performBackgroundRender();

    }


    public update(delta: number): void
    {
        // todo: multithreading
        //Log.log("delta: " + delta.toFixed(2), this);

      this.zoomAnimator.update(delta);
      if (!this.zoomAnimator.isFinished) {
        const prev = this.zoom;
        this.zoom = this.zoomAnimator.getValue();

        let xPrevShown = this.width / prev;
        let xShown = this.width / this.zoom;
        let yPrevShown = this.height / prev;
        let yShown = this.height / this.zoom;
        let xChange = (xShown - xPrevShown) * (this.width * .5 / xShown) / this.zoom;
        let yChange = (yShown - yPrevShown) * (this.height * .5 / yShown) / this.zoom;
        //this.position = new Vector(this.position.x - xChange, this.position.y - yChange, 0);
        this.position = this.position.sub(new Vector(xChange, yChange, 0));
      }


      let l = null;
      for (let layer of this.layers)
        {
          layer.update(delta * this.timeScale, this.zoom);
          layer.isDirty = true; // todo: remove
          if (layer.hitOrMiss || !l || layer.isContentHit()) {
            l = layer;
          }
        }
        this.hitLayer = l;

    }


    render(): void
    {
        // todo: multithreading


        let batch = new Batch(this.ctx, this);
        //this.clear();
        batch.ctx.fillStyle = '#fafafa';//"rgb(222, 222, 222)";
        batch.clearAll();
        batch.ctx.fillRect(0, 0, this.dimensions.width, this.dimensions.height);

        for (let layer of this.layers)
        {
          if (layer.isDirty)
            layer.render(batch);
          batch.ctx.globalAlpha = layer.alpha;
          batch.drawLayer(layer.fbo.handle, batch.position.x, -batch.position.y);
          batch.ctx.globalAlpha = 1;
        }

    }


    public autoRun(): void
    {
        let start = performance.now();
        this.update(performance.now() - this.lastFrameTimestamp);
        this.lastFrameTimestamp = performance.now();
        let upd = performance.now() - start;
        this.render();
        //if (performance.now() - start > 16)
        //  Log.log("Update: " + upd.toFixed(2) + "ms Render: " + (performance.now() - start - upd).toFixed(2) + "ms total: " + (performance.now() - start).toFixed(2) + "ms");

      if (environment.debug) {
        document.querySelector("#FPS").innerHTML = (Math.round( (performance.now() - start)) + " ms");
        document.querySelector("#FPSU").innerHTML = (Math.round( upd) + " ms");
      }
        let tp = this;
        requestAnimationFrame(function ()
        {
            tp.autoRun();
        });
    }

    protected updateSize()
    {
        this.canvasElement.setAttribute("width", window.innerWidth);
        this.canvasElement.setAttribute("height", window.innerHeight);

        this.dimensions = new Dimensions(window.innerWidth, window.innerHeight);


        for (let layer of this.layers)
        {
            layer.updateSize();
        }

/*
        this.update(performance.now() - this.lastFrameTimestamp);
        this.render();

        this.lastFrameTimestamp = performance.now();*/
    }

    public getDimensions(): Dimensions
    {
        return this.dimensions;
    }

    public addLayer(layer: Layer): void
    {
        this.layers.push(layer);
    }


    public clear(): void
    {
        this.ctx.clearRect(0, 0, this.getDimensions().width, this.getDimensions().height);
    }

    public registerObject(element: RenderObject): void
    {
        element.uuid = this.pool.add(element);
    }
    public unregisterObject(index: number): void
    {
        this.pool.remove(index);
    }



    get width(): number
    {
        return this.dimensions.width;
    }
    get height(): number
    {
        return this.dimensions.height;
    }


  get zoom(): number {
    return this._zoom;
  }

  set zoom(value: number) {
    this._zoom = Math.min(this.maxZoom, Math.max(value, this.minZoom));
  }


  public setZoom(pos: Vector): any
  {
    let prev = this.zoom;
    this.zoom = this.zoom + pos.z * .0005 * Math.min(1, Math.pow(this.zoom, 1.2));

    //this.xOff += this.xOff * (prev - this.zoom);
    //this.yOff += this.yOff * (prev - this.zoom);

    let xPrevShown = this.width / prev;
    let xShown = this.width / this.zoom;
    let yPrevShown = this.height / prev;
    let yShown = this.height / this.zoom;
    let xChange = (xShown - xPrevShown) * (pos.x / xShown) / this.zoom;
    let yChange = (yShown - yPrevShown) * (pos.y / yShown) / this.zoom;
    //this.position = new Vector(this.position.x - xChange, this.position.y - yChange, 0);
    this.position = this.position.sub(new Vector(xChange, yChange, 0));
  }

  public addToBackgroundRender(uuid: number)
  {
    if (this.backgroundRenderList.indexOf(uuid) == -1)
      this.backgroundRenderList.push(uuid);
  }

  public enableBackgroundRender() {
      this.isBackgroundRenderActive = true;
  }

  public performBackgroundRender(maxExecutionTime: number = 15)
  {
    if (this.isBackgroundRenderActive) {
      const startTime = performance.now();
      //Log.log("render bg");
      let c = 0;

      if (this.backgroundRenderList.length > 0)  // check if batch creation is needed
      {
        const batch = new Batch(this.dummyCanvas.getContext("2d"), null);
        batch.isBackgroundBatch = true;
        this.backgroundRenderList = BUtils.shuffle(this.backgroundRenderList);

        while (this.backgroundRenderList.length > 0)
        {

          const uuid = this.backgroundRenderList.pop();
          let element = this.pool.get(uuid);

          //Log.log("rendered in bg: " + uuid);
          element.render(batch);
          c++;
          if (startTime + maxExecutionTime <= performance.now())
            break;
        }
      }

      if (c > 0)
        Log.log("rendered " + c + " elements in bg");

    }

    let tp = this;
    requestAnimationFrame(function ()
    {
      tp.performBackgroundRender(maxExecutionTime);
    });


  }

  public setAllDirty() {
      for (const layer of this.layers) {
        layer.setAllDirty();
      }
  }

  public get worldPosition(): Vector {
      return this.position.add(new Vector(this.dimensions.width2 / this.zoom, this.dimensions.height2 / this.zoom)).sub(new Vector(this.dimensions.width2, this.dimensions.height2));
  }

  public getHoverCursor(): string {
      if (this.hitLayer)
        return this.hitLayer.preferredHoverCursor();
      return 'auto';
  }
  public getClickCursor(): string {
      if (this.hitLayer)
        return this.hitLayer.preferredClickCursor();
      return 'auto';
  }

  public click(event: MouseEvent): Actions {
    const myEvent = new Event(event, new Batch(this.ctx, this));
    if (this.hitLayer) {
      this.hitLayer.click(myEvent);
    }

    /*for (const action of result) {
      action.hierarchy.canvas = this;
    }*/
      return myEvent.actions;
  }
  public mouseUp(event: MouseEvent): Actions {
    const myEvent = new Event(event, new Batch(this.ctx, this));
    if (this.hitLayer) {
      this.hitLayer.mouseUp(myEvent);
    }

    /*for (const action of result) {
      action.hierarchy.canvas = this;
    }*/
      return myEvent.actions;
  }
  public doubleClick(event: MouseEvent): Actions {
    const myEvent = new Event(event, new Batch(this.ctx, this));
    if (this.hitLayer) {
      this.hitLayer.doubleClick(myEvent);
    }

    /*for (const action of result) {
      action.hierarchy.canvas = this;
    }*/
    return myEvent.actions;
  }

  public layerByName(name: string): Layer {
      for (const layer of this.layers) {
        if (layer.name === name)
          return layer;
      }
      return null;
  }

  public get hasDragged(): boolean {
      for (const layer of this.layers) {
        if (layer.hasDragged) {
          return true;
        }
      }
      return false;
  }

}
