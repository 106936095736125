import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Canvas} from './src/app/view/eleanor/canvas';
import './src/app/view/eleanor/main';
import {CanvasConfig} from './src/app/view/eleanor/canvasConfig';
import {ObjectLayer} from './src/app/view/eleanor/objectLayer';
import {TestCircle} from './src/app/view/eleanor/shapes/testCircle';
import {Vector} from './src/app/view/eleanor/tools/vectors';
import {ConnectionLayer} from './src/app/view/connection/connectionLayer';
import {Text} from './src/app/view/eleanor/text/text';
import {Dimensions} from './src/app/view/eleanor/tools/dimensions';
import {Font} from './src/app/view/eleanor/text/font';
import {Block} from './src/app/view/node/block';
import {tryCatch} from 'rxjs/internal-compatibility';
import {Builder} from './src/app/view/node/builder';
import {NodeLayer} from './src/app/view/node/nodeLayer';
import {FieldImporter} from './src/app/view/field/fieldImporter';
import {OfflineFieldImporter} from './src/app/view/field/offlineFieldImporter';
import {HammerGestureConfig} from '@angular/platform-browser';
import {HammerGesturesPlugin} from '@angular/platform-browser/src/dom/events/hammer_gestures';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {OnlineFieldImporter} from './src/app/view/field/onlineFieldImporter';
import {Log} from './src/app/view/eleanor/tools/log';
import {NavigationCoordinates} from './src/app/view/NavigationCoordinates';
import * as $ from 'jquery';
import {Field} from './src/app/view/field/field';
import {TfField} from './src/app/model/tfField';
import {Event} from './src/app/view/eleanor/tools/event';

import * as Hammer from 'hammerjs';
import {ActionHandler} from './src/app/view/eleanor/interfaces/actionHandler';
import {ModalComponent} from './src/app/modal/modal.component';
import {UiLayer} from './src/app/view/eleanor/uiLayer';
import {Observable} from 'rxjs';
import {ModalConfig} from './src/app/view/eleanor/action/modalConfig';
import {ModalEvent} from './src/app/view/events/modalEvent';
import {Uuid} from './src/app/view/eleanor/tools/uuid';
import {map, switchMap} from 'rxjs/internal/operators';
import {environment} from "../environments/environment";
import {Actions} from "./src/app/view/eleanor/action/actions";
import {MoveEvent} from "./src/app/view/eleanor/tools/moveEvent";
import {EmptyAction} from './src/app/view/eleanor/action/emptyAction';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, ActionHandler {
    title = 'thinkfield';
    canvas: Canvas;
    hasMoved: boolean;
    model: TfField;
    field: Field;
    importer: FieldImporter;
    @Output() public navigatedToCoordinates = new EventEmitter<NavigationCoordinates>();
    @Output() public modalEvents = new EventEmitter<ModalEvent>();
    modal: ModalComponent;

    public allowPinchEvent: boolean = true;
    public allowScrollEvent: boolean = true;
    public allowZoomEvent: boolean = true;

    environment = environment;

    constructor(private route: ActivatedRoute, private router: Router) {

      // maybe this is what safari needs
      //window.addEventListener('gesturestart', e => e.preventDefault());
      //window.addEventListener('gesturechange', e => e.preventDefault());
      //window.addEventListener('gestureend', e => e.preventDefault());

    }

    public navigationEvent(event: NavigationCoordinates){
        this.navigatedToCoordinates.emit(event);
    }
    ngAfterViewInit(): void {

      console.log('scroll:');
      console.log();
      const mi = this;
      setTimeout(function () {


      }, 3000);
      // Stolen //
      $(document).keydown(function(event) {
        if ((event.ctrlKey || event.metaKey) && (event.which == '61' || event.which == '107' || event.which == '173' || event.which == '109'  || event.which == '187'  || event.which == '189'  ) ) {
          //event.preventDefault();
        }
        // 107 Num Key  +
        // 109 Num Key  -
        // 173 Min Key  hyphen/underscor Hey
        // 61 Plus key  +/= key
      });

      this.model = null;
      this.field = null;

      const self = this;

      //let hammer = new Hammer(document.getElementById('canvas'));
      //hammer.get('pinch').set({ enable: true });
      //hammer.on('pinchin', function (e) {
      //  self.pinch(e);
      //});
      //hammer.on('pinchout', function (e) {
      //  self.pinch(e);
      //});

      $(window).bind('mousewheel DOMMouseScroll', function (event) {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
        }
      });

      // /Stolen //

        //test initial state
        this.navigationEvent({x:0,y:0,scale:1.0});

        (<any>window).bp2dInit(
            {
                'poolSize': 100,
                'debug': true,
            }
        );


        this.canvas = (<any>window).bp2dInitCanvas(
            (<any>document.querySelector('#canvas')),
            new CanvasConfig(
                true, // continuous render
                false, // aa
                false, // force redraw
                true, // pixel perfect
                true, // fullscreen
                500, // height
                700, // width
                100 // pool size
            ),
        );


        this.router.events.subscribe((val) => {
                if (!(val instanceof RoutesRecognized)) {
                    return;
                }
                else {
                    console.log(self.route.snapshot.queryParams);
                    let token = '';
                    console.log(val.state.root.firstChild.params);
                    let params = val.state.root.firstChild.params;
                    console.log('subbed: ' + token);
                    token = params['token'];
                    let getParams: string = params['params'];
                    if (!getParams)
                      getParams = '';
                    console.log(getParams);
                    const queryParams = getParams.split(',');
                    self.allowScrollEvent = !queryParams.includes('noscroll');
                    self.allowZoomEvent = !queryParams.includes('nozoom');
                    self.allowPinchEvent = !queryParams.includes('nopinch');
                    console.log('new token is ' + JSON.stringify(params));
                    let loadingTexts = [
                        'hello there :)',
                        'hang in there ^-^',
                        'just a moment ^o^',
                        'follow us on <a href=\'https://www.facebook.com/thinkfieldapp/\'>facebook</a> :D',
                        'eat your vegetables ;)',
                        'get ready ^_^',
                    ];

                    console.log('subbed 68: ' + token);

                    //console.info($);
                    //$('body').addClass('gray-bg');
                    //$("#modal .imageHolder .tip p").html(loadingTexts[Math.floor(Math.random() * loadingTexts.length)]);

                    if (!token) {
                        alert('Dude, y u no /field/?');
                    }

                    if (token.length <= 0) {
                        console.info('sets token to def');
                        token = 'karkanIsTheGreatest';
                    }


                    //FieldImporter.getField("facebook_1568387783230267%2Fthinkfield", "1272AD56-3F9F-42B7-A6BE-83EA1D0C260A", init);
                    //FieldImporter.getField("facebook_10208652285597199%2Fthinkfield", "AAB4C76C-F565-4888-B65E-71ED55DAAA5B", init);
//token = "karkanIsTheGreatest"
                    if (token == '') {
                        window.location.href = 'https://thinkfield.li';
                    }
                    else {

                        //self.importer = new OfflineFieldImporter(this.canvas);
                        self.importer = new OnlineFieldImporter(this.canvas, token);
                        //importer.model();
                        //importer.view();
                    }
                }
            }
        );


        (<any>window).x = new Text('adsf asdf asd fasd fasdf asd f', new Vector(), new Dimensions(400, 400));
        const l2 = new ObjectLayer(this.canvas, 100);
        l2.addObject((<any>window).x);
        l2.modPosition = new Vector();
        l2.modZoom = new Vector(0, 1);
        l2.fixedZoom = 1;
        //this.canvas.addLayer(l2);


        const canvas = this.canvas;

        try {
            (<any>document).fonts.ready.then(function () {
                console.log('fonts loaded');
                self.asyncStart();

            });
        } catch (e) {

        }


    }

    protected async asyncStart() {
      while (!this.importer) {
          //this.asyncStart();
          await new Promise(resolve => setTimeout(resolve, 150));
      }


      this.importer.lessonOneOrRatherCircleOneHeHe();


      while (!this.importer.isFinished) {
        //this.asyncStart();
        await new Promise(resolve => setTimeout(resolve, 150));
      }

      console.log("field created");
      this.model = this.importer.getModel();
      this.field = this.importer.getField();
        //self.canvas.update(0);
        //self.canvas.render();

      const self = this;
        setTimeout(function () {
            self.canvas.setAllDirty();
            self.canvas.enableBackgroundRender();
        }, 100);
    }

    public screenTranslate(x: number, y: number): Vector {
        const bounds = this.canvas.canvasElement.getBoundingClientRect();
        return new Vector(
            x - bounds.left,
            y + bounds.top
        );
    }

    public click(event: MouseEvent) {
        console.log("click");
        console.log(event);
        if (!this.canvas) {
          this.hasMoved = false;
          console.log("canvas not initialized, so no click event will be fired");
          return;
        } else if (this.hasMoved) {
          if (this.canvas.hasDragged) {
            //this.processActions(this.canvas.mouseUp(event));
            return;
          } else {
            const uiLayer: UiLayer = <UiLayer> this.canvas.layerByName('static_ui');
            //console.log('uilayer', uiLayer, uiLayer.hitElements);
            if (!uiLayer || !uiLayer.isContentHit()) {
              console.log("moved (without drag), so no click event will be fired");
              return;
            }
          }
        }

        //console.log("click");
        /*const actions = this.canvas.click(event);
        console.log(actions);
        for (const action of actions) {
          action.perform(this);
        }*/
        this.processActions(this.canvas.click(event));

        //let item = this.canvas.itemAtPos(event.clientX,event.clientY)
        //TODO: @karkan here we can do something like location.href
        // todo: @chris no :D
        //also, if clickable there should be a hover mouse like so:
        // $('canvas').style.cursor = (onClickableItem) ? .pointer : hand;
        //window.open( "https://google.com","__blank");
        //this.canvas.click(event.clientX, event.clientY);
    }

    public doubleClick(event: MouseEvent) {
      console.log("double click");


      /*const actions = this.canvas.doubleClick(event);
      console.log(actions);
      for (const action of actions) {
        action.perform(this);
      }*/
      this.processActions(this.canvas.doubleClick(event));
    }

    public mouseDown(event: any) {
        //console.log('mousedown');
        //console.log(event);

        const center = event.center ? new Vector(event.center.x, event.center.y) : new Vector(event.clientX, event.clientY);
        this.endMove(center);

        if (!this.canvas)
            return;

        if (this.canvas.hitLayer && this.canvas.hitLayer.blockCanvasMove()) {
          /*const actions = this.canvas.hitLayer.startMove(new Event(event, this.canvas.hitLayer.getBatch()));
          for (const action of actions) {
            action.hierarchy.canvas = this.canvas;
            action.perform(this);
          }*/
          this.processActions(this.canvas.hitLayer.startMove(new MoveEvent(event, this.canvas.hitLayer.getBatch())).actions);
          this.hasMoved = true;  // todo: this is a hack and should be removed. Reason: when 'hasMoved' is not set to 'true', mouse up event will fire as a click
        } else if (this.anchorScreen == null) {
            this.startMove(center);
        }
    }

    public mouseUp(event: any) {
        //console.log("mousedown");
        //console.log(event);
        const center = event.center ? new Vector(event.center.x, event.center.y) : new Vector(event.clientX, event.clientY);
        this.endMove(center);

        if (this.canvas) {
          for (const layer of this.canvas.layers) {
            /*const actions = layer.endMove(new Event(event, layer.getBatch()));
            for (const action of actions) {
              action.hierarchy.canvas = this.canvas;
              action.perform(this);
            }*/
            this.processActions(layer.endMove(new Event(event, layer.getBatch())).actions);
          }
        }
    }

    public mouseOut(event: MouseEvent) {
        //console.log("mouseout");
        //console.log(event);
        this.endMove(new Vector(event.clientX, event.clientX));


      if (this.canvas) {
        for (const layer of this.canvas.layers) {
          /*const actions = layer.endMove(new Event(event, layer.getBatch()));
          for (const action of actions) {
            action.hierarchy.canvas = this.canvas;
            action.perform(this);
          }*/
          this.processActions(layer.endMove(new Event(event, layer.getBatch())).actions);
        }
      }
    }

    public pointerOut(event: MouseEvent) {
        //console.log("pointerout");
        //console.log(event);
        event.preventDefault();
    }

    public contextMenu(event: MouseEvent) {
        //console.log("Context");
        //console.log(event);
        event.preventDefault();
        if (!this.canvas)
            return;
        //this.hover(event);
    }

    public hover(event: any) {
        //console.log("hover");
        //console.log(event);
        const center = event.center ? new Vector(event.center.x, event.center.y) : new Vector(event.clientX, event.clientY);
        if (!this.canvas)
            return;


        if (this.canvas) {
          for (const layer of this.canvas.layers) {
            /*const actions = layer.onMove(new Event(event, layer.getBatch()));
            for (const action of actions) {
              action.hierarchy.canvas = this.canvas;
              action.perform(this);
            }*/
            this.processActions(layer.onMove(new Event(event, layer.getBatch())).actions);
          }
        }

        if (this.anchorScreen != null) {
            this.move(center);
            //console.log($(this.canvas.canvasElement));
            $(this.canvas.canvasElement).css('cursor', this.canvas.getClickCursor());
        }
        else {
            this.canvas.cursor = center;
          $(this.canvas.canvasElement).css('cursor', this.canvas.getHoverCursor());
            //console.log('new cursor' + this.canvas.cursor);
        }
        //this.canvas.hover(event.clientX, event.clientY);
    }

    public scroll(event: MouseEvent) {
        //console.log("scroll");
        //console.log(event);
    }

    public wheel(event: WheelEvent) {
      if (!this.allowScrollEvent) {
        return;
      }
      console.log("wheel");
      console.log(event);
      let delta = event.wheelDeltaY || event.deltaY;
      console.log("zooming: " + delta);
      if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        // Do Firefox-related activities
        delta = delta * -50;
      }
      this.zoom(this.screenTranslate(event.clientX, event.clientY).add(new Vector(0, 0, delta)));
    }



    public lastPinch: number;

  public pinchstart(event) {
    if (!this.allowPinchEvent) {
      return;
    }
    console.log("pinchstart");
    console.log(event);
    event.preventDefault();
    this.lastPinch = 1;
    this.startMove(new Vector(event.center.x, event.center.y));
    //this.zoom(this.screenTranslate(event.center.x, event.center.y).add(new Vector(0, 0, event.distance)));
  }

    public pinchin(event) {
        //console.log("pinchin");
        //console.log(event);
        event.preventDefault();
        if (event.scale < this.lastPinch) {
          this.zoom(this.screenTranslate(event.center.x, event.center.y).add(new Vector(0, 0, (event.scale - this.lastPinch) * 1000)));
        }
      this.lastPinch = event.scale;
    }
  public pinchout(event) {
        //console.log("pinchout");
        //console.log(event);
        event.preventDefault();
        this.zoom(this.screenTranslate(event.center.x, event.center.y).add(new Vector(0, 0, event.scale * 25)));
    }
    public pinchend(event) {
      if (!this.allowPinchEvent) {
        return;
      }
        console.log("pinchend");
        console.log(event);
        event.preventDefault();
        this.endMove(new Vector(event.center.x, event.center.y));
        this.lastPinch = 1;
    }
    public pinchmove(event) {
        //console.log("pinchmove");
        //console.log(event);
        event.preventDefault();
        this.move(new Vector(event.center.x, event.center.y));
    }

    public pinch(event) {
      if (!this.allowPinchEvent) {
        return;
      }
      console.log("pinch");
      //console.log(event);
      console.log("l: " + this.lastPinch + ", c: " + event.scale);
      event.preventDefault();

      this.zoom(this.screenTranslate(event.center.x, event.center.y).add(new Vector(0, 0, (event.scale - this.lastPinch) * 4000)));
      this.lastPinch = event.scale;
      //this.move(new Vector(event.center.x, event.center.y));
      //this.canvas.position = this.anchorPos.add(this.anchorScreen.sub(pos).div(new Vector(this.canvas.zoom, this.canvas.zoom)));
      //console.log(new Vector(event.center.x, event.center.y).str());
    }


    protected anchorScreen: Vector = null;
    protected anchorPos: Vector = null;

    public startMove(pos: Vector) {
        this.anchorScreen = pos;
        this.anchorPos = this.canvas.position;
      this.hasMoved = false;
      let uiLayer = this.canvas.layerByName('ui');
      if (uiLayer) {
        uiLayer.fadeOut();
      }
      uiLayer = this.canvas.layerByName('static_ui');
      if (uiLayer) {
        uiLayer.fadeOut();
      }
    }

    public move(pos: Vector) {

        /*console.log("---------------");
        console.log("canvas 1: " + this.canvas.position.x + " " + this.canvas.position.y);
        console.log("anchor C: " + this.anchorPos.x + " " + this.anchorPos.y);
        console.log("anchor S: " + this.anchorScreen.x + " " + this.anchorScreen.y);
        console.log("Positi M: " + pos.x + " " + pos.y);*/
        this.canvas.position = this.anchorPos.add(this.anchorScreen.sub(pos).div(new Vector(this.canvas.zoom, this.canvas.zoom)));
        this.hasMoved = true;
        //console.log(this.canvas.position);
        //console.log(this.canvas.layers[0].position);
        //console.log("");
      this.navigationEvent({x: this.canvas.worldPosition.x, y: this.canvas.worldPosition.y, scale: this.canvas.zoom});
    }

    public endMove(pos: Vector) {
        this.anchorScreen = null;
        this.anchorPos = null;
      let uiLayer = this.canvas.layerByName('ui');
      if (uiLayer) {
        uiLayer.fadeIn();
      }
      uiLayer = this.canvas.layerByName('static_ui');
      if (uiLayer) {
        uiLayer.fadeIn();
      }
    }

    public zoom(pos: Vector) {
        //this.canvas.zoom = this.canvas.zoom + pos.z * .0005;
        this.canvas.setZoom(pos);
      this.navigationEvent({x: this.canvas.worldPosition.x, y: this.canvas.worldPosition.y, scale: this.canvas.zoom});
        //console.log(this.canvas.zoom);
    }


    public redraw() {
        Log.log('set all dirty');
        console.log('set all dirty');
        this.canvas.setAllDirty();
    }

    public fieldCreatedCallback(importer: FieldImporter) {
      console.log("field created");
      this.model = importer.getModel();
    }





    /** Action Handler Stuff here, すか**/

    openUrl(url: string, target: string) {
      window.open(url, target);
    }

  smoothZoomIn() {
      //this.canvas.zoomAnimator.startValue = this.canvas.zoom;
      //this.canvas.zoomAnimator.targetValue = this.canvas.zoom + .1;
      //this.canvas.zoomAnimator.isFinished = false;
      //this.canvas.zoomAnimator.restart();
      //this.canvas.zoomAnimator.forward();
      //console.log(this.canvas.zoomAnimator);
    this.canvas.zoomAnimator.new(this.canvas.zoom, this.canvas.zoom * 1.25, 300, true);
  }

  smoothZoomOut() {
    this.canvas.zoomAnimator.new(this.canvas.zoom, this.canvas.zoom * .75, 300, true);
  }

  openModal(config: ModalConfig) {
      const self = this;
      console.log(self);
      let model = null;
      for (const id in this.field.modelToNode) {
        if (this.field.modelToNode[id] == config.viewItem) {
          console.log('found id: ' + id);
          console.log(this.model.map);
          model = this.model.map.get(id);
          break;
        }
      }
      const event: ModalEvent = new class implements ModalEvent {
        handler = self;
        type = config.type;
        viewItem = config.viewItem;
        modelItem = model;
      };
      this.modalEvents.emit(event);
  }

  processActions(actions: Actions) {
      //console.log(actions.all());
      for (const action of actions.all()) {
        action.hierarchy.canvas = this.canvas;
        if (action.shouldPerform()) {
          action.perform(this);
        }
      }
  }





}
