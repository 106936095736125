import {environment} from '../../../../../../environments/environment';


export class Log {

    public static debug: boolean = environment.log;

    public static log(msg: string, obj?: any): void
    {
        if (this.debug)
        {

            if (obj)
            {
                console.log("[" + obj.constructor.name + "]: " + msg);
            }
            else
            {
                console.log("[Log]: " + msg);
            }
        }
    }
    public static info(msg: any, obj?: any): void
    {
        if (this.debug)
        {

            if (obj)
            {
                console.log("[" + obj.constructor.name + "] in next line: ");
                console.log(msg);
            }
            else
            {
                console.log("[Log] in next line: ");
                console.log(msg);
            }
        }
    }



}
