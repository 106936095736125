
import {Dimensions} from "../eleanor/tools/dimensions";
import {Batch} from "../eleanor/batch";
import {Mask} from "../eleanor/tools/mask";


export class RoundClip extends Mask {


    public dimensions: Dimensions;
    public radius: number;


    constructor(dimensions: Dimensions, radius: number) {
        super();
        this.dimensions = dimensions;
        this.radius = radius;
    }

    protected mask(batch: Batch): void {

        let r = this.radius;

        batch.moveTo(-this.width2 + r, this.height2);

        batch.lineTo(this.width2 - r, this.height2);
        batch.quadraticCurveTo(this.width2, this.height2, this.width2, this.height2 - r);

        batch.lineTo(this.width2, -this.height2 + r);
        batch.quadraticCurveTo(this.width2, -this.height2, this.width2 - r, -this.height2);

        batch.lineTo(-this.width2 + r, -this.height2);
        batch.quadraticCurveTo(-this.width2, -this.height2, -this.width2, -this.height2 + r);

        batch.lineTo(-this.width2, this.height2 - r);
        batch.quadraticCurveTo(-this.width2, this.height2, -this.width2 + r, this.height2);
    }





    /*
    protected clip(batch: Batch): void
    {


        let r = this.radius;

        batch.moveTo             (-this.width2, this.height2 - r);
        batch.lineTo             (-this.width2, this.height2);
        batch.lineTo             (-this.width2 + r, this.height2);
        batch.quadraticCurveTo   (-this.width2, this.height2, -this.width2, this.height2 - r);
        batch.moveTo             (this.width2 - r,this.height2);
        batch.lineTo             (this.width2, this.height2);
        batch.lineTo             (this.width2, this.height2 - r);
        batch.quadraticCurveTo   (this.width2, this.height2, this.width2 - r, this.height2);
        batch.moveTo             (this.width2, -this.height2 + r);
        batch.lineTo             (this.width2, -this.height2);
        batch.lineTo             (this.width2 - r, -this.height2);
        batch.quadraticCurveTo   (this.width2, -this.height2, this.width2, -this.height2 + r);
        batch.moveTo             (-this.width2 + r, -this.height2);
        batch.lineTo             (-this.width2, -this.height2);
        batch.lineTo             (-this.width2, -this.height2 + r);
        batch.quadraticCurveTo   (-this.width2, -this.height2, -this.width2 + r, -this.height2);

    }
    */


    get width(): number
    {
        return this.dimensions.width;
    }
    get height(): number
    {
        return this.dimensions.height;
    }
    get width2(): number
    {
        return this.dimensions.width * .5;
    }
    get height2(): number
    {
        return this.dimensions.height * .5;
    }

}
