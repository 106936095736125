import {Action} from './action';
import {ActionHandler} from '../interfaces/actionHandler';


export class LinkAction extends Action {

  public url: string;
  public target: string;


  constructor(url: string, target: string) {
    super();
    this.url = url;
    this.target = target;
  }

  perform(actionHandler: ActionHandler) {
    actionHandler.openUrl(this.url, this.target);
  }



}
