import {RenderObject} from '../eleanor/renderObject';
import {Batch} from '../eleanor/batch';
import {Vector} from '../eleanor/tools/vectors';
import {Dimensions} from '../eleanor/tools/dimensions';
import {MoveEvent} from "../eleanor/tools/moveEvent";


export class Mover extends RenderObject {

  protected elements: Array<RenderObject>;
  protected originalPositions: Array<Vector>;
  protected start: Vector;
  private _current: Vector;


  constructor(elements: Array<RenderObject>) {
    super();
    this.elements = elements;
    this._current = new Vector();
    this.originalPositions = [];
    for (const element of this.elements) {
      this.originalPositions.push(element.position);
    }
    this.position = new Vector();
    this.dimensions = new Dimensions(0, 0);
  }

  render(batch: Batch): void {
  }


  update(delta: number, zoom: number = 1): void {
    super.update(delta, zoom);

    for (let i = 0; i < this.elements.length; i++) {
      const before = this.elements[i].position;
      this.elements[i].position = this.originalPositions[i].add(this.current);
      this.elements[i].onMove(new MoveEvent(null, null, before, this.elements[i].position.sub(before)));
    }

  }


  get current(): Vector {
    return this._current;
  }

  set current(value: Vector) {
    this._current = value;
  }
}

